import { ApolloError } from 'apollo-client';
import { KeyedMutator } from 'swr';

import useTypedSWR from 'src/components/general/useTypedSWR';
import { PharmaciesQuery, Staff_Pharmacy as PharmacyType } from 'src/generated/gql/graphql';
import { PHARMACIES_QUERY } from 'src/pharmacyRecommendations/usePharmacyRecommendations.gql';

export function usePharmacyRecommendations(
  city: string | null,
  name: string | null,
  state: string | null,
  zip: string | null,
): { pharmacies: PharmacyType[]; error?: ApolloError; mutate: KeyedMutator<PharmaciesQuery> } {
  const { data, error, mutate } = useTypedSWR(
    city || name || state || zip ? [PHARMACIES_QUERY, { city, name, state, zip }] : null,
    { revalidateOnFocus: false },
  );
  if (!data?.staff_pharmacies) {
    return { pharmacies: [], error, mutate };
  }

  return { pharmacies: data.staff_pharmacies, error, mutate };
}
