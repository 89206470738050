import { CircularProgress, makeStyles } from '@material-ui/core';
import { Subscription } from 'apollo-client/util/Observable';
import React, { useEffect, useRef, useState } from 'react';

import { OnVisitCodeUsedSubscription } from 'src/generated/gql/graphql';
import { SEND_VISIT_CODE_EVENT, VISIT_CODE_USED } from 'src/inviteViaCode/queries.gql';
import { randomDigits } from 'src/inviteViaCode/rng';
import useApolloClient from 'src/shared/client/useApolloClient';

type InviteViaCodeProps = {
  /** The ID of the event we're inviting to the video for */
  eventId: string;
  /** Called when someone has joined using the current visit code */
  onJoin: () => void;
};

/**
 * V2 of the InviteViaCode component
 */
const InviteViaCode: React.FC<InviteViaCodeProps> = ({ eventId, onJoin }) => {
  const classes = useStyles();
  // initialize component w/ a generated random 6 digit code to display
  const [code] = useState<string>(randomDigits(6));

  // initialize apollo client to use for subscription and mutation
  const apolloClient = useApolloClient();

  const activeSubscription = useRef<Subscription>();
  const [subscriptionUpdate, setSubscriptionUpdate] = useState<
    OnVisitCodeUsedSubscription | null | undefined
  >(null);

  // subscribe to get an observable, which will update when gql subscription receives data
  if (!activeSubscription.current) {
    activeSubscription.current = apolloClient
      ?.subscribe<OnVisitCodeUsedSubscription>({ query: VISIT_CODE_USED, variables: { code } })
      .subscribe(({ data: updateData }) => setSubscriptionUpdate(updateData));
  }

  useEffect(() => {
    // when values are ready, send mutation that visit code has been displayed
    if (subscriptionUpdate?.staff_onVisitCodeUsed.code && apolloClient && code && eventId) {
      const emitVisitCodeUsedEvent = () => {
        apolloClient.mutate<{ staff_sendVisitCodeEvent: string }>({
          mutation: SEND_VISIT_CODE_EVENT,
          variables: { code: subscriptionUpdate?.staff_onVisitCodeUsed.code, eventId },
        });
      };
      const sendEvent = () => {
        emitVisitCodeUsedEvent();
        onJoin();
      };
      sendEvent();
    }
  }, [subscriptionUpdate, onJoin, code, apolloClient, eventId]);

  return (
    <>
      <p>
        Tell the guest to open <span className={classes.visitUrl}>visit.boulder.care</span> and
        enter this Visit ID:
      </p>
      {code && (
        <h1 className={classes.visitCode}>{`${code.substring(0, 3)} ${code.substring(3, 6)}`}</h1>
      )}
      <div className={classes.italic}>
        This code is only valid while this window is open. Waiting for participant to join...{' '}
        <CircularProgress className={classes.loadingVisitCode} size={12} />
      </div>
    </>
  );
};

const useStyles = makeStyles({
  italic: {
    fontStyle: 'italic',
  },
  visitUrl: {
    textDecoration: 'underline',
    fontWeight: 'bold',
  },
  progress: {
    padding: 10,
  },
  visitCode: {
    letterSpacing: 20,
  },
  loadingVisitCode: {
    verticalAlign: 'middle',
  },
});

export default InviteViaCode;
