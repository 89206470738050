import type { ChartOverviewSection } from 'src/nightingale/types/types';

/**
 * Get pure text from HTML string
 * @see {@link https://stackoverflow.com/a/6743966}
 */
const getTextFromHTML = (html: string): string => {
  if (!html) return '';
  const container = document.createElement('div');
  container.innerHTML = html;
  return container.innerText || container.textContent || '';
};

/**
 * Searches given string for search term, case insensitive
 */
const search = (target: string, searchTerm: string): boolean =>
  target ? target.toLowerCase().indexOf(searchTerm) >= 0 : false;

/**
 * Performs a case-insensitive search on patient overview
 *
 * matches across:
 *  OverviewSection names
 *  ChartElement labels
 *  ChartElement inlineHelp
 *  ChartElement summaries (includes notes)
 *
 * if a section name has a match - all of the elements are shown
 * chart elements being edited are always shown - this is controlled at the section level
 *
 * this function will set a hidden property for sections and elements that did not match the search
 * this function will not literally filter elements
 *
 * this function blocks the render of patient overview -
 * we will have to keep an eye out for performance as the amount of searchable data grows.
 * if this function takes a while to complete, it will introduce a noticable delay into the search ui.
 *
 * @param sections patient overview sections
 * @param text search text
 * @returns chart sections with a hidden property set for sections and elements that did not match the search
 */
export const filterChartSections = (
  sections: ChartOverviewSection[],
  text: string,
): ChartOverviewSection[] => {
  if (!text) return sections;

  const searchTerm = text.toLowerCase();

  return (sections || []).map(section => {
    const { elements, label } = section;

    if (label && search(label, searchTerm)) {
      return section;
    }

    let hasVisibleElement = false;
    const filteredElements = elements.map(element => {
      const { label: elementLabel, parsedInlineHelp, summarization } = element;

      if (
        (elementLabel && search(elementLabel, searchTerm)) ||
        (parsedInlineHelp && search(getTextFromHTML(parsedInlineHelp), searchTerm)) ||
        (summarization && search(getTextFromHTML(summarization), searchTerm))
      ) {
        hasVisibleElement = true;
        return element;
      }

      return {
        ...element,
        hidden: true,
      };
    });

    return {
      ...section,
      elements: filteredElements,
      hidden: !hasVisibleElement,
    };
  });
};
