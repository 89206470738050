import { TableCell, TableRow } from '@material-ui/core';
import gql from 'graphql-tag';
import startCase from 'lodash/startCase';
import React, { useCallback, useContext } from 'react';

import { Select } from 'src/common/ui/components';
import { ApolloClientContext } from 'src/data/ApolloClientContext';
import {
  Staff_Pharmacy as Pharmacy,
  Staff_RecommendationType as PharmacyRecommendation,
} from 'src/generated/gql/graphql';

export enum NotRecommendedReason {
  RefusedTelehealth = 'REFUSED_TELEHEALTH',
  NotAcceptingNewPatients = 'NOT_ACCEPTING_NEW_PATIENTS',
  UncomfortableWithBoulderModel = 'UNCOMFORTABLE_WITH_BOULDER_MODEL',
  IndependentPharmacistRefusal = 'INDEPENDENT_PHARMACIST_REFUSAL',
  MonoproductLimitations = 'MONOPRODUCT_LIMITATIONS',
  RefusedOutsideOfProximity = 'REFUSED_OUTSIDE_OF_PROXIMITY',
  NoMATPrescriptions = 'NO_MAT_PRESCRIPTIONS',
}

export enum RecommendedReason {
  FriendlyPharmacist = 'FRIENDLY_PHARMACIST',
  AlignedWithBoulderModel = 'ALIGNED_WITH_BOULDER_MODEL',
}

const PHARMACY_RECOMMENDATION_MUTATION = gql(`
  mutation UpdatePharmacyRecommendation($pharmacyId: ID!, $recommendation: staff_RecommendationType $recommendationReasons: [String!]) {
    staff_updatePharmacyRecommendation(pharmacyId: $pharmacyId, recommendation: $recommendation, recommendationReasons: $recommendationReasons)
  }
`);

// Functions for converting enums to/from display formats
const startCaseToUpperSnakeCase = (str: string) => str.replace(/\s+/g, '_').toUpperCase();
const upperSnakeCaseToStartCase = (str: string) => startCase(str.toLowerCase());

export const PharmacyItem = ({
  pharmacy,
  updatePharmacyList,
  allowPharmacyUpdates,
}: {
  pharmacy: Pharmacy;
  updatePharmacyList: (pharmacy: Pharmacy) => void;
  allowPharmacyUpdates?: boolean;
}) => {
  const { apolloClient } = useContext(ApolloClientContext);

  const handleRecommendationChange = (_evt: React.SyntheticEvent, val: string | null) => {
    const setVal = val ? startCaseToUpperSnakeCase(val) : null;

    if (setVal === pharmacy.recommendation) {
      return;
    }

    update({ recommendation: setVal });
  };

  const handleRecommendationReasonChange = (_evt: React.SyntheticEvent, val: string[] | null) => {
    const setVal = val?.length ? val.map(startCaseToUpperSnakeCase) : null;

    if (setVal === pharmacy.recommendationReasons) {
      return;
    }

    update({ recommendationReasons: setVal });
  };

  const update = useCallback(
    async rec => {
      try {
        updatePharmacyList({ ...pharmacy, ...rec });
        await apolloClient?.mutate({
          mutation: PHARMACY_RECOMMENDATION_MUTATION,
          variables: {
            pharmacyId: pharmacy.id,
            ...rec,
          },
        });
      } catch (e) {
        updatePharmacyList(pharmacy);
        console.error('Error updating pharmacy recommendation');
        console.error(e);
      }
    },
    [apolloClient, pharmacy, updatePharmacyList],
  );

  const recommendationReasons =
    pharmacy.recommendation === PharmacyRecommendation.NotRecommended
      ? Object.values(NotRecommendedReason)
      : Object.values(RecommendedReason);

  return (
    <TableRow key={pharmacy.id}>
      <TableCell>{pharmacy.name}</TableCell>
      <TableCell>
        {pharmacy.street1}{' '}
        {pharmacy.street2 && (
          <>
            <br />
            {pharmacy.street2}
          </>
        )}
      </TableCell>
      <TableCell>{pharmacy.city}</TableCell>
      <TableCell>{pharmacy.state}</TableCell>
      <TableCell>{pharmacy.zip}</TableCell>
      <TableCell style={{ width: '15%' }}>
        <Select
          options={Object.values(PharmacyRecommendation)}
          getOptionLabel={option => upperSnakeCaseToStartCase(option)}
          onInputChange={handleRecommendationChange}
          value={pharmacy.recommendation}
          readOnly={!allowPharmacyUpdates}
        />
      </TableCell>
      <TableCell style={{ width: '25%' }}>
        <Select
          multiple
          options={recommendationReasons}
          getOptionLabel={option => upperSnakeCaseToStartCase(option)}
          onChange={handleRecommendationReasonChange}
          value={pharmacy.recommendationReasons ?? []}
          readOnly={!allowPharmacyUpdates}
        />
      </TableCell>
    </TableRow>
  );
};
