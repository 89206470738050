import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

import RouteLink from 'src/components/general/routeLink';
import { Pebble } from 'src/pebbles/types';

const PARTICIPANT_LINKS = [
  { label: 'Overview', route: 'overview' },
  { label: 'Activity', route: 'activity' },
  { label: 'Documents', route: 'documents' },
];

const PebbleParticipantView: React.FC<{
  name: string | null;
  participant: Pebble['participant'];
}> = ({ name, participant }) => {
  const classes = useParticipantStyles();

  return participant ? (
    <Typography variant="body2">
      {`${name ?? '[name unknown]'}: `}
      {PARTICIPANT_LINKS.map(({ label, route }, key) => (
        <React.Fragment key={route}>
          {!!key && ' | '}
          <RouteLink
            route="showPatient"
            newWindow
            routeParams={[{ id: participant.id, tab: route }]}
            className={classes.participantLink}
          >
            {label}
          </RouteLink>
        </React.Fragment>
      ))}
    </Typography>
  ) : (
    <Typography variant="body2">—</Typography>
  );
};

const useParticipantStyles = makeStyles({
  participantLink: {
    color: 'inherit',
  },
});

export default PebbleParticipantView;
