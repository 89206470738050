import isNil from 'lodash/isNil';
import lowerCase from 'lodash/lowerCase';
import startCase from 'lodash/startCase';

import {
  AnyChartProperty,
  ChartPropertyAction,
  ChartPropertyTypes,
  ChartPropertyValue,
  ChartPropertyValueChangeSet,
  ListChartProperty,
  ObjectChartProperty,
} from 'src/nightingale/types/types';

/**
 * Default property value object
 */
export const DEFAULT_CHART_PROPERTY_VALUE = {
  isEmpty: false,
  notes: null,
  value: null,
};

/**
 * Format titles based on names (IDs)
 */
export const titleCase = (str: string): string => startCase(lowerCase(str));

/*
 * If the label key does not exist on the chart property definition,
 * we generate one using property.name, which is a camelCased
 * string.
 * We also have to dive recursively into the childProperties collection
 * (if it exists on the chart property) so we can calculate each of the
 * child labels as well.
 */
export function withImplicitLabel<T extends AnyChartProperty>(property: T): T {
  let transform = { ...property };

  if (transform.type === ChartPropertyTypes.List || transform.type === ChartPropertyTypes.Object) {
    const complex = transform as ListChartProperty | ObjectChartProperty;
    complex.properties = complex.properties?.map(child => withImplicitLabel(child)) ?? null;
    transform = complex as T;
  }

  transform.label = property.label || titleCase(property.name);

  return transform;
}

/**
 * Compose valid chart property value from updated fields, chart property definition and defaults
 */
export const createValidChartPropertyValue = (
  changeset: ChartPropertyValueChangeSet,
  definition: AnyChartProperty,
  defaults = DEFAULT_CHART_PROPERTY_VALUE,
): ChartPropertyValue => {
  const {
    action = ChartPropertyAction.Update,
    isEmpty: changesetIsEmpty,
    notes: changesetNotes,
    propertyName,
    value: changesetValue,
  } = changeset;
  const { isEmpty: definitionIsEmpty, notes: definitionNotes, value: definitionValue } = definition;
  const { isEmpty: defaultsIsEmpty, notes: defaultsNotes, value: defaultsValue } = defaults;

  let isEmpty: boolean;
  if (typeof changesetIsEmpty === 'boolean') isEmpty = changesetIsEmpty;
  else if (typeof definitionIsEmpty === 'boolean') isEmpty = definitionIsEmpty;
  else isEmpty = defaultsIsEmpty;

  let notes: string | null;
  if (changesetNotes === '' && definitionNotes) notes = defaultsNotes;
  else if (changesetNotes) notes = changesetNotes;
  else if (definitionNotes) notes = definitionNotes;
  else notes = defaultsNotes;

  let value: any;
  if (changesetValue !== undefined) value = changesetValue;
  else if (!isNil(definitionValue)) value = definitionValue;
  else value = defaultsValue;

  if (isEmpty) value = null;

  return {
    action,
    isEmpty,
    notes,
    propertyName,
    value,
  };
};

/**
 * Get standardized empty values for chart properties depending on type
 */
export const getEmptyValueForChartProperty = (
  type: ChartPropertyTypes,
  defaultValue?: any,
): any => {
  switch (type) {
    case ChartPropertyTypes.Email:
    case ChartPropertyTypes.Integer:
    case ChartPropertyTypes.LongText:
    case ChartPropertyTypes.Phone:
    case ChartPropertyTypes.TaggedText:
    case ChartPropertyTypes.Text:
      return defaultValue || '';
    case ChartPropertyTypes.Boolean:
    case ChartPropertyTypes.CheckboxList:
    case ChartPropertyTypes.Date:
    case ChartPropertyTypes.List:
    case ChartPropertyTypes.Object:
    case ChartPropertyTypes.Select:
    case ChartPropertyTypes.SelectAsync:
    case ChartPropertyTypes.SelectMulti:
    case ChartPropertyTypes.SelectMultiAsync:
    default:
      return defaultValue || null;
  }
};

/**
 * Check if a chart property value is empty. Not the same as checking if
 * marked as none.
 */
export const isEmptyValue = (propertyValue: ChartPropertyValue['value']): boolean =>
  isNil(propertyValue) || // null or undefined
  propertyValue === '' || // empty text based properties
  (Array.isArray(propertyValue) && propertyValue.length === 0) || // empty list properties
  (typeof propertyValue === 'object' &&
    !(propertyValue instanceof Date) &&
    Object.values(propertyValue).every(isEmptyValue)); // object properties with all values set to one of the above

/**
 * Check if a chart property has been explicitly marked as none.
 */
export const isMarkedAsNone = (property: Pick<AnyChartProperty, 'isEmpty' | 'value'>) =>
  property.value === null && property.isEmpty;
