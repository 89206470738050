import useTypedSWR from 'src/components/general/useTypedSWR';
import { GET_PATIENT_BULK_NOTIFICATIONS_OPTIONS_QUERY } from 'src/smsCommunications/hooks/useGetPatientBulkNotificationsOptions.gql';

export type Message = {
  label: string;
  key: string;
  value: string;
};

export const useGetPatientBulkNotificationsOptions = (): Message[] => {
  const { data } = useTypedSWR(GET_PATIENT_BULK_NOTIFICATIONS_OPTIONS_QUERY);

  return data?.getPatientBulkNotificationsOptions ?? [];
};
