import { EventCard, EventCardClassification } from 'src/generated/gql/graphql';
import { ProviderRole } from 'src/stores/users/userType';

/**
 * We sometimes hide nextEvent cards from providers if certain conditions are met.
 * This is to prevent confusion and to ensure that the provider is not
 * overwhelmed with information that is not relevant to them at the moment.
 *
 * This function determines whether an event card should be rendered.
 *
 * @param {EventCard} nextEvent - The event card to evaluate.
 * @param {ProviderRole} providerTeamRole - The role of the my-day-today provider
 * @returns {boolean} - Returns `true` if the event card should be rendered, otherwise `false`.
 *
 */
export const shouldRenderEventCard = (
  nextEvent: EventCard,
  providerTeamRole: ProviderRole,
): boolean => {
  const { classification, hasBeenSufficientlyNotified, prepInProgress } = nextEvent ?? {};

  if (prepInProgress) {
    return false;
  }

  const providerIsClinician = providerTeamRole === ProviderRole.Clinician;
  const isDropInEvent = classification === EventCardClassification.DropIn;
  const patientNotSufficientlyNotified = !hasBeenSufficientlyNotified;

  if (providerIsClinician && isDropInEvent && patientNotSufficientlyNotified) {
    // don't bug clinicians about a drop in event that's not ready yet
    return false;
  }

  return true;
};
