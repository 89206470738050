import { Box, Tab, Tabs } from '@material-ui/core';
import React, { useState, useContext } from 'react';

import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import ClinicRequestQueue from 'src/dropInClinic/ClinicRequestQueue';
import { CompletedVisitList } from 'src/dropInClinic/completedVisits/CompletedVisitList';
import { CompletedVisits } from 'src/dropInClinic/completedVisits/CompletedVisits';
import { ProviderAvailabilities } from 'src/dropInClinic/providerAvailability';
import { ScheduledQueueSettings } from 'src/dropInClinic/queueSettings/ScheduledQueueSettings';
import { useStyles } from 'src/dropInClinic/styles';
import { ProviderSuggestedAssignments } from 'src/dropInClinic/suggestedAssignments';
import { DropInClinicSection } from 'src/dropInClinic/types';
import {
  COMPLETED_VISITS_PROGRESS,
  DROP_IN_ATC_INTERFACE,
  RESTRICT_QUEUE_PAGE_ACCESS,
} from 'src/featureFlags/currentFlags';
import { PageTitle } from 'src/nightingale/components/common/PageTitle/PageTitle';
import { Provider } from 'src/shared/stores/resource';
import useRootStore from 'src/stores/useRootStore';
import { ProviderRole } from 'src/stores/users/userType';

export type DropInClinicProps = {
  activeSection: DropInClinicSection;
};

export const DropInClinic = (props: DropInClinicProps) => {
  const classes = useStyles();
  const rootStore = useRootStore();

  const [activeSection, setActiveSection] = useState(props.activeSection);
  const flags = useContext(FeatureFlagContext);
  const showSettings = !!flags[DROP_IN_ATC_INTERFACE];
  const showCompletedVisitsProgress = !!flags[COMPLETED_VISITS_PROGRESS];
  const restrictQueuePageAccess = !!flags[RESTRICT_QUEUE_PAGE_ACCESS];

  let CompletedVisitsComponent = CompletedVisitList;
  if (showCompletedVisitsProgress) {
    CompletedVisitsComponent = CompletedVisits;
  }

  const isClinician = (rootStore.auth.user as Provider)?.teamRole === ProviderRole.Clinician;

  const UnauthorizedView = () => <div>Unauthorized - You do not have access to this page</div>;

  if (isClinician && restrictQueuePageAccess) {
    return <UnauthorizedView />;
  }

  return (
    <div className={classes.pageContainer}>
      <PageTitle>Pop-In Clinic</PageTitle>
      <Box style={{ borderBottom: '1px solid', marginTop: -15 }}>
        <Tabs value={activeSection} onChange={(_, v) => setActiveSection(v)}>
          <Tab value={DropInClinicSection.Queue} label="Current Queue" />
          <Tab value={DropInClinicSection.CompletedVisits} label="Completed Visits" />
          {showSettings && <Tab value={DropInClinicSection.Settings} label="Queue Settings" />}
          <Tab value={DropInClinicSection.Availability} label="Availability" />
          <Tab value={DropInClinicSection.SuggestedAssignments} label="Suggested Assignments" />
        </Tabs>
      </Box>
      {activeSection === DropInClinicSection.Queue && <ClinicRequestQueue />}
      {activeSection === DropInClinicSection.CompletedVisits && <CompletedVisitsComponent />}
      {activeSection === DropInClinicSection.Settings && <ScheduledQueueSettings />}
      {activeSection === DropInClinicSection.Availability && <ProviderAvailabilities />}
      {activeSection === DropInClinicSection.SuggestedAssignments && (
        <ProviderSuggestedAssignments />
      )}
    </div>
  );
};
