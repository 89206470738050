import React, { CSSProperties } from 'react';

import { BoulderIconMap, type BoulderIconName } from 'src/common/ui/components/icons';

type BoulderIconProps = {
  className?: string;
  icon: BoulderIconName;
  size?: number;
  onClick?: () => void;
};

export const BoulderIcon = ({ className, icon, size, onClick }: BoulderIconProps) => {
  const IconComponent = BoulderIconMap[icon];
  const style: CSSProperties = {};

  if (size) {
    style.width = size;
    style.height = size;
  }

  return <IconComponent className={className} size={size} style={style} onClick={onClick} />;
};
