import Button from '@material-ui/core/Button';
import { useRouterStore } from 'mobx-state-router';
import React, { useContext, useCallback, Dispatch, SetStateAction, useState } from 'react';

import { generateRouteUrl } from 'src/boot/routes';
import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import ConfirmDialog from 'src/components/pages/pageElements/confirmDialog';
import { ApolloClientContext } from 'src/data/ApolloClientContext';
import {
  ACCEPT_MUTATION,
  PREPARING_MUTATION,
  COMPLETE_PREP_MUTATION,
} from 'src/dropInClinic/queries';
import {
  AcceptMutationResponse,
  AcceptMutationArgs,
  PrepareMutationResponse,
  PrepareMutationArgs,
  CompletePrepMutationResponse,
  CompletePrepMutationArgs,
  DropInQueueEntry,
} from 'src/dropInClinic/types';
import { RESTRICT_QUEUE_PAGE_ACCESS } from 'src/featureFlags/currentFlags';
import { ProviderRole } from 'src/stores/users/userType';

const preparableRequests = new Set(['INTAKE']);

export const ActionButtons = ({
  entry: { requestId, requestType, status, acceptable, hasBeenSufficientlyNotified },
  providerRole,
  setRequestUpdateError,
  mutate,
}: {
  entry: Pick<
    DropInQueueEntry,
    'requestId' | 'requestType' | 'status' | 'acceptable' | 'hasBeenSufficientlyNotified'
  >;
  providerRole: ProviderRole | null;
  setRequestUpdateError: Dispatch<SetStateAction<null>>;
  mutate: () => void;
}) => {
  const [requestToConfirmPrep, setRequestToConfirmPrep] = useState<string | null>(null);

  const { apolloClient } = useContext(ApolloClientContext);
  const router = useRouterStore();
  const flags = useContext(FeatureFlagContext);
  const restrictQueuePageAccess = !!flags[RESTRICT_QUEUE_PAGE_ACCESS];

  const displayPrepareButton =
    preparableRequests.has(requestType) &&
    providerRole === ProviderRole.CareAdvocate &&
    status === 'SUBMITTED';

  const displayCompletePrepButton =
    !displayPrepareButton &&
    preparableRequests.has(requestType) &&
    providerRole === ProviderRole.CareAdvocate &&
    ['PREPARING', 'PREPARED'].includes(status);

  const requirePrejoinTime = !restrictQueuePageAccess && !hasBeenSufficientlyNotified;

  const handleAccept = useCallback(
    async (id: string) => {
      setRequestUpdateError(null);
      if (!apolloClient) {
        return;
      }

      try {
        const response = await apolloClient.mutate<AcceptMutationResponse, AcceptMutationArgs>({
          mutation: ACCEPT_MUTATION,
          variables: { requestId: id },
        });

        const eventId = response.data?.acceptDropInClinicRequest.eventId;
        if (eventId) {
          const videoUrl = generateRouteUrl('eventVc', { event: eventId });
          window.open(videoUrl, '_blank');
          router.goTo('showEvent', { params: { id: eventId } });
        }
      } catch (e) {
        setRequestUpdateError(e);
      }
      mutate();
    },
    [apolloClient, setRequestUpdateError, mutate, router],
  );

  const handlePreparing = useCallback(
    async (id: string) => {
      setRequestUpdateError(null);
      if (!apolloClient) {
        return;
      }

      try {
        const response = await apolloClient.mutate<PrepareMutationResponse, PrepareMutationArgs>({
          mutation: PREPARING_MUTATION,
          variables: { requestId: id },
        });
        const eventId = response.data?.prepareDropInClinicRequest.eventId;
        const prepEventId = response.data?.prepareDropInClinicRequest.prepEventId;

        if (prepEventId) {
          const videoUrl = generateRouteUrl('eventVc', { event: prepEventId });
          window.open(videoUrl, '_blank');
          router.goTo('showEvent', { params: { id: prepEventId } });
        } else if (eventId) {
          const videoUrl = generateRouteUrl('eventVc', { event: eventId });
          window.open(videoUrl, '_blank');
          router.goTo('showEvent', { params: { id: eventId } });
        }
      } catch (e) {
        setRequestUpdateError(e);
      }
      mutate();
    },
    [apolloClient, setRequestUpdateError, mutate, router],
  );

  const handleCompletePrep = useCallback(
    async (id: string) => {
      setRequestUpdateError(null);
      if (!apolloClient) {
        return;
      }

      try {
        await apolloClient.mutate<CompletePrepMutationResponse, CompletePrepMutationArgs>({
          mutation: COMPLETE_PREP_MUTATION,
          variables: { requestId: id },
        });
      } catch (e) {
        setRequestUpdateError(e);
      }
      mutate();
    },
    [apolloClient, setRequestUpdateError, mutate],
  );

  return (
    <>
      {acceptable && (
        <Button
          variant="contained"
          color="primary"
          data-request-id={requestId}
          onClick={() => handleAccept(requestId)}
          disabled={status === 'PREPARING' || status === 'CHECKING_IN' || requirePrejoinTime}
          data-testid="drop-in-accept"
        >
          Accept
        </Button>
      )}
      {displayPrepareButton && (
        <Button
          variant="contained"
          color="primary"
          data-request-id={requestId}
          onClick={() => handlePreparing(requestId)}
          data-testid="drop-in-prepare"
        >
          Prepare
        </Button>
      )}
      {displayCompletePrepButton && (
        <Button
          variant="contained"
          color="primary"
          data-request-id={requestId}
          onClick={() => setRequestToConfirmPrep(requestId)}
          disabled={status === 'PREPARED'}
          data-testid="drop-in-prepared"
        >
          Complete Prep
        </Button>
      )}
      {requestToConfirmPrep && (
        <ConfirmDialog
          onSubmit={() => {
            handleCompletePrep(requestToConfirmPrep);
            setRequestToConfirmPrep(null);
          }}
          onCancel={() => setRequestToConfirmPrep(null)}
          submitLabel="Yes, prep complete"
          cancelLabel="No, prep incomplete"
        >
          Have you completed the prep for this patient?
        </ConfirmDialog>
      )}
    </>
  );
};
