import { FormControl, InputLabel, makeStyles } from '@material-ui/core';
import type { KeyboardDatePickerProps } from '@material-ui/pickers';
import { KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import { addMilliseconds, format, isValid as isValidDate, subMilliseconds } from 'date-fns';
import { formatInTimeZone, getTimezoneOffset, toDate } from 'date-fns-tz';
import type { FormikValues } from 'formik';
import React, { useCallback, useState } from 'react';
import type { Option } from 'react-select/lib/filters';

import Autocomplete from 'src/components/forms/controls/autocomplete';
import SelectControl from 'src/components/forms/controls/select';
import { TIMEZONES } from 'src/util/timezones';
import type { TimezoneName } from 'src/util/timezones';

enum DurationScale {
  Minutes = 1,
  Hours = 60,
}

const getOptionsForDurationScale = (durationScale: DurationScale) =>
  durationScale === DurationScale.Hours ? DURATION_OPTIONS_HOURS : DURATION_OPTIONS_MINUTES;

const formatValueForDurationScale = (durationScale: DurationScale, value: number) =>
  durationScale !== DurationScale.Minutes ? (value / durationScale).toFixed(2) : value;

const createOptionWithDurationScale = (
  durationScale: DurationScale,
  inputValue: string,
): Option | null => {
  const asFloat = Number.parseFloat(inputValue);

  if (Number.isNaN(asFloat)) {
    return null;
  }

  const effectiveValue = asFloat * durationScale;

  const option = {
    value: `${effectiveValue}`,
    label: inputValue,
    data: effectiveValue,
  };

  return option;
};

const DateAndTimes: React.FC<DateAndTimesProps> = ({
  allDay,
  allowHours = false,
  durationName,
  form,
  label = 'Date',
  startName,
  timezoneName,
}) => {
  const classes = useStyles();

  const { touched, errors, values, setFieldTouched } = form;
  const isAllDay = allDay(values);

  const [durationScale, setDurationScale] = useState(DurationScale.Minutes);

  const start: Date = values[startName];
  const duration: number = values[durationName];
  const timezone: TimezoneName = values[timezoneName];

  const handleChangeDate = useCallback<KeyboardDatePickerProps['onChange']>(
    (value: Date) => {
      if (isValidDate(value)) {
        // For all day events, the start time should be YY-MM-DDT00:00:00Z.
        form.setFieldValue(
          startName,
          new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate())),
        );
      }
    },
    [form, startName],
  );

  const handleChangeStartTime = useCallback(
    (newStart: Date) => {
      if (isValidDate(newStart)) {
        const template = format(newStart, 'yyyy-MM-dd HH:mm:ss');
        form.setFieldValue(startName, toDate(template, { timeZone: timezone }));
      }
    },
    [form, startName, timezone],
  );

  const handleChangeDuration = useCallback<(option: Option) => void>(
    option => {
      if (Number.isNaN(option.data)) {
        return;
      }

      form.setFieldValue(durationName, option.data);
    },
    [durationName, form],
  );

  const handleChangeDurationScale = useCallback(event => {
    setDurationScale(event.target.value);
  }, []);

  const handleChangeTimezone = useCallback(
    event => {
      const { value } = event.target;

      form.setFieldValue(timezoneName, value);

      const offsetChange = getTimezoneOffset(timezone, start) - getTimezoneOffset(value, start);
      form.setFieldValue(startName, addMilliseconds(start, offsetChange));
    },
    [form, start, startName, timezone, timezoneName],
  );

  return (
    <FormControl fullWidth className={classes.formControl}>
      <InputLabel htmlFor="date-start" shrink>
        {label}
      </InputLabel>
      {isAllDay ? (
        <KeyboardDatePicker
          autoOk
          className={classes.dateField}
          emptyLabel=""
          error={errors[startName] && touched[startName]}
          // This format string is equivalent to `P` in date-fns, and using that token will work
          // for displaying dates selected with the picker. However, it fails to parse text input;
          // we're probably missing a locale setting somewhere.
          format="MM/dd/yyyy"
          helperText={touched[startName] && errors[startName]}
          id="date-start"
          invalidLabel=""
          onBlur={() => setFieldTouched(startName)}
          onChange={handleChangeDate}
          placeholder="MM/DD/YYYY"
          // all-day events start at 00:00Z, which is typically "yesterday" in the US, so to keep
          // the conceputal "start time" in line with the UI, we'll lie to the date picker and say
          // the start time is 00:00 local instead.
          value={subMilliseconds(
            start,
            getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone, start),
          )}
        />
      ) : (
        <>
          <KeyboardDateTimePicker
            autoOk
            className={classes.dateTimeField}
            error={errors[startName] && touched[startName]}
            format="MM/dd/yyyy hh:mm a"
            helperText={touched[startName] && errors[startName]}
            id="date-start"
            onBlur={() => setFieldTouched(startName)}
            onChange={handleChangeStartTime}
            placeholder="MM/DD/YYYY hh:mm"
            inputValue={formatInTimeZone(start, timezone, 'MM/dd/yyyy hh:mm a')}
            value={null}
          />
          <div className={classes.time}>
            <span className={classes.timeFieldText}>for</span>
            <Autocomplete
              creatable
              formatCreateLabel={(inputValue: string) => <>{inputValue}</>}
              getNewOptionData={(inputValue: string) =>
                createOptionWithDurationScale(durationScale, inputValue)
              }
              isValidNewOption={(inputValue: string): boolean => !Number.isNaN(Number(inputValue))}
              className={classes.durationField}
              classes={{ paper: classes.durationDropdown }}
              error={errors[durationName] && touched[durationName]}
              helperText={touched[durationName] && errors[durationName]}
              id="duration"
              getOptionLabel={(option: Option) =>
                formatValueForDurationScale(durationScale, option.data)
              }
              getOptionValue={(option: Option) => option.data}
              maxMenuHeight={200}
              onBlur={() => setFieldTouched(durationName)}
              onChange={handleChangeDuration}
              options={getOptionsForDurationScale(durationScale)}
              value={{ value: duration.toString(), label: duration.toString(), data: duration }}
            />
            {allowHours ? (
              <SelectControl
                className={classes.durationScaleField}
                field={{
                  name: 'durationScale',
                  value: durationScale,
                  onChange: handleChangeDurationScale,
                  onBlur: () => {},
                }}
                form={{ touched, errors }}
                options={[
                  { label: 'minutes', value: DurationScale.Minutes },
                  { label: 'hours', value: DurationScale.Hours },
                ]}
                data-testid="duration-scale-field"
              />
            ) : (
              <span className={classes.timeFieldText}>minutes</span>
            )}
            <SelectControl
              field={{
                name: timezoneName,
                value: timezone,
                onChange: handleChangeTimezone,
                onBlur: () => setFieldTouched(timezoneName),
              }}
              form={{ touched, errors }}
              options={TIMEZONES}
              className={classes.timezoneField}
            />
          </div>
        </>
      )}
    </FormControl>
  );
};

export interface DateAndTimesProps {
  allDay: (event: { type: string; subtype: string }) => boolean;
  // If set allow either minutes or hours to be selected for the duration input;
  // By default, allow only minutes
  allowHours?: boolean;
  durationName: string;
  form: FormikValues;
  label?: string;
  startName: string;
  timezoneName: string;
}

export const DURATION_OPTIONS_MINUTES: readonly Option[] = [
  { label: '15', value: '15', data: 15 },
  { label: '20', value: '20', data: 20 },
  { label: '30', value: '30', data: 30 },
  { label: '40', value: '40', data: 40 },
  { label: '60', value: '60', data: 60 },
];

export const DURATION_OPTIONS_HOURS: readonly Option[] = [
  { label: '1.00', value: '1', data: 60 },
  { label: '2.00', value: '2', data: 120 },
  { label: '4.00', value: '4', data: 240 },
  { label: '8.00', value: '8', data: 480 },
];

const useStyles = makeStyles({
  formControl: {
    alignItems: 'baseline',
    display: 'inline-flex',
    flexDirection: 'row',
  },
  dateField: {
    marginRight: 20,
    marginTop: 12,
    verticalAlign: 'inherit',
    width: 180,
  },
  dateTimeField: {
    marginRight: 20,
    marginTop: 12,
    verticalAlign: 'inherit',
    width: 240,
  },
  time: {
    alignItems: 'baseline',
    display: 'inline-flex',
    flexDirection: 'row',
  },
  durationField: {
    display: 'inline-flex',
    marginRight: 20,
    verticalAlign: 'inherit',
    width: 100,
  },
  durationDropdown: {
    marginTop: 32,
  },
  durationScaleField: {
    marginRight: 20,
    width: 125,
  },
  timeFieldText: {
    marginRight: 20,
  },
  timezoneField: {
    width: 200,
  },
});

export default DateAndTimes;
