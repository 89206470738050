import { gql } from 'src/generated/gql';

export const GET_PATIENT_BULK_NOTIFICATIONS_OPTIONS_QUERY = gql(`
  query GetPatientBulkNotificationsOptions {
    getPatientBulkNotificationsOptions {
      label
      key
      value
    }
  }
`);
