import { format } from 'date-fns';

import PatientDataService from 'src/data/patients/PatientDataService';
import PossibleDuplicateChartsArgs from 'src/duplicateCharts/PossibleDuplicateChartsArgs';

/**
 * Patient search query expects a dob in the format MM/dd/yyyy
 */
export const formatDobForPatientSearchQuery = (dob: string) => {
  // replace dashes w/ slashes to avoid off by one day conversion
  const convertedDob = dob.replace('-', '/');

  // use format expected by patient search query
  return format(new Date(convertedDob), 'MM/dd/yyyy');
};

function stringsAreEqualCaseInsensitive(a: string, b: string): boolean {
  return a.trim().localeCompare(b.trim(), undefined, { sensitivity: 'base' }) === 0;
}

/**
 * Find exact matches on name and dob
 * Returns a list of matching lookups that are not the current patient
 */
export const findExactNameAndDobMatch = async ({
  possibleDuplicateChartsArgs,
  patientDataService,
}: {
  possibleDuplicateChartsArgs: Partial<PossibleDuplicateChartsArgs>;
  patientDataService: PatientDataService;
}) => {
  // search for an exact match on name and dob
  const { patientId, firstName, lastName, dob } = possibleDuplicateChartsArgs;

  if (!dob || !patientId) return [];

  return (await patientDataService.search(formatDobForPatientSearchQuery(dob))).filter(
    lookup =>
      // get exact name matches from dob list, ignoring the currentPatient's record
      firstName &&
      stringsAreEqualCaseInsensitive(lookup.firstName, firstName) &&
      lastName &&
      stringsAreEqualCaseInsensitive(lookup.lastName, lastName) &&
      lookup.id !== patientId,
  );
};
