/** @jsxImportSource @emotion/react */
import MuiAutocomplete, { type AutocompleteProps } from '@mui/material/Autocomplete';
import { type ChipTypeMap } from '@mui/material/Chip';
import MuiTextField from '@mui/material/TextField';
import React from 'react';

import { BoulderIcon } from 'src/common/ui/components/BoulderIcon';
import { selectMultipleCss, selectSingleCss } from 'src/common/ui/components/Select/Select.css';

/**
 * Autocomplete Select Box. Supports selecting multiple and custom options.
 *
 * @see {@link https://mui.com/material-ui/api/autocomplete/}
 */
export function Select<
  Value,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
>({
  autoSelect,
  defaultValue,
  disabled,
  freeSolo,
  getOptionLabel,
  id,
  label,
  loading,
  multiple,
  onChange,
  onClose,
  onInputChange,
  onOpen,
  open,
  openOnFocus,
  options,
  readOnly,
  value,
}: { label?: string } & Pick<
  AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>,
  | 'autoSelect'
  | 'defaultValue'
  | 'disabled'
  | 'freeSolo'
  | 'getOptionLabel'
  | 'id'
  | 'loading'
  | 'multiple'
  | 'onChange'
  | 'onClose'
  | 'onInputChange'
  | 'onOpen'
  | 'open'
  | 'openOnFocus'
  | 'options'
  | 'readOnly'
  | 'value'
>) {
  if (multiple && !Array.isArray(value) && !Array.isArray(defaultValue)) {
    throw new Error(
      'Select – when `multiple` is true, `value` or `defaultValue` must be an array.',
    );
  }

  return (
    <MuiAutocomplete
      autoSelect={autoSelect}
      clearOnEscape
      css={multiple ? selectMultipleCss : selectSingleCss}
      data-testid="nds-component-select"
      defaultValue={defaultValue}
      disablePortal
      ChipProps={{
        deleteIcon: <BoulderIcon icon="delete" size={22} />,
      }}
      disabled={disabled}
      freeSolo={freeSolo}
      fullWidth
      getOptionLabel={getOptionLabel}
      id={id}
      includeInputInList
      loading={loading}
      multiple={multiple}
      onChange={onChange}
      onClose={onClose}
      onInputChange={onInputChange}
      onOpen={onOpen}
      open={open}
      openOnFocus={openOnFocus}
      options={options}
      readOnly={readOnly}
      renderInput={params => <MuiTextField {...params} label={label} variant="standard" />}
      value={value}
    />
  );
}
