import React, { useContext, useState } from 'react';

import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import Tooltip from 'src/components/general/Tooltip';
import { FLOW_ACTIVE_ELEMENT_CONDITIONAL_OVERRIDE } from 'src/featureFlags/currentFlags';
import { ChartContextProvider } from 'src/nightingale/components/ChartContext/ChartContext';
import { ChartElement } from 'src/nightingale/components/ChartElement/ChartElement';
import { ConditionalContext } from 'src/nightingale/components/ConditionalContext/ConditionalContext';
import { FlowSearch, SelectOption } from 'src/nightingale/components/Flow/Flow.Search';
import { useStyles } from 'src/nightingale/components/Flow/Flow.styles';
import { SectionHeader } from 'src/nightingale/components/SectionHeader/SectionHeader';
import { getFlowStepType } from 'src/nightingale/data/Flow.utils';
import { RequiredChartPropertiesContextProvider } from 'src/nightingale/requiredChartProperties/RequiredChartPropertiesContext';
import type {
  InteractionKind,
  ChartElement as TChartElement,
  Flow as TFlow,
} from 'src/nightingale/types/types';
import { FlowStepType } from 'src/nightingale/types/types';

export type FlowProps = {
  setParentActiveElement?: (string) => void;
  allAddableFlowIds: string[];
  flow: TFlow;
  interactionId?: string;
  interactionKind?: InteractionKind | null;
  interactionReferenceId?: string;
  /**
   * 0-based index of the nested depth of the current Flow.
   */
  nestedLevel?: number;
  onAddFlow: (value: SelectOption, parentFlowIndex?: number) => void;
  onRefreshSnapshot?: () => void;
  onRemoveFlow: (flowId: string) => void;
  patientId: string;
  /**
   * 0-based index of the current Flow's position in it's parent Flow.
   */
  position?: number;
  readOnly?: boolean;
  selectableFlows: TFlow[];
};

export const Flow: React.FC<FlowProps> = ({
  allAddableFlowIds,
  flow,
  position = 0,
  nestedLevel = 0,
  onAddFlow,
  onRefreshSnapshot,
  onRemoveFlow,
  readOnly,
  selectableFlows,
  setParentActiveElement,
  ...rest
}) => {
  const isRemovable = allAddableFlowIds.includes(flow.id) && !readOnly;
  const canAddFlowsAfter = !readOnly && nestedLevel === 1;
  const hasHeader = !!flow.header;

  const [isHighlighted, setIsHighlighted] = useState(false);
  const styles = useStyles({ hasHeader, isHighlighted, isRemovable });
  const { conditionalMap } = useContext(ConditionalContext);

  // The activeElement state is used to indicate that an element is currently focussed. Knowing
  // that the element is focussed is then used to prevent the element from being destroyed if
  // it would normally be removed due to a conditional evaluation that fires on render. Removing
  // an element before it has had a chance to save any changes can cause unexpected behavior
  // in the chart. See HAX-779 for more information.
  const [activeElement, setActiveElement] = useState<string | undefined>();

  const flags = useContext(FeatureFlagContext);
  const flowActiveElementConditionalOverrideEnabled =
    flags[FLOW_ACTIVE_ELEMENT_CONDITIONAL_OVERRIDE];

  return (
    <ChartContextProvider
      patientId={rest.patientId}
      interactionId={rest.interactionId}
      interactionKind={rest.interactionKind}
      interactionReferenceId={rest.interactionReferenceId}
      isReadOnly={readOnly}
      onRefreshSnapshot={onRefreshSnapshot}
    >
      <RequiredChartPropertiesContextProvider
        requiredChartProperties={flow.requiredChartPropertiesConfig}
      >
        <section data-testid="flow" data-test-name={flow.name} className={styles.container}>
          <div className={styles.content}>
            <header className={styles.header}>
              {hasHeader ? <SectionHeader>{flow.header}</SectionHeader> : null}

              {isRemovable && (
                <Tooltip title="Hide section">
                  <button
                    className={styles.removeBtn}
                    data-testid="remove-addable-flow"
                    onClick={() => onRemoveFlow(flow.id)}
                    onMouseEnter={() => setIsHighlighted(true)}
                    onMouseLeave={() => setIsHighlighted(false)}
                    type="button"
                  >
                    Hide section
                  </button>
                </Tooltip>
              )}
            </header>

            {flow.elements?.map((element, index) => {
              // override conditional logic if we have an active element
              const activeElementOverride =
                flowActiveElementConditionalOverrideEnabled && activeElement === element.id;

              if (
                element.id in conditionalMap &&
                !conditionalMap[element.id] &&
                !activeElementOverride
              ) {
                return null;
              }

              switch (getFlowStepType(element)) {
                case FlowStepType.Flow:
                  return (
                    <Flow
                      allAddableFlowIds={allAddableFlowIds}
                      flow={element as TFlow}
                      key={`flow-${element.name}`}
                      nestedLevel={nestedLevel + 1}
                      onAddFlow={onAddFlow}
                      onRemoveFlow={onRemoveFlow}
                      position={index}
                      readOnly={readOnly}
                      selectableFlows={selectableFlows}
                      setParentActiveElement={
                        flowActiveElementConditionalOverrideEnabled
                          ? elementId => {
                              // set our active element
                              setActiveElement(elementId);

                              // if falsey we are clearing state otherwise set parent active element
                              if (setParentActiveElement)
                                setParentActiveElement(!elementId ? undefined : flow.id);
                            }
                          : undefined
                      }
                      {...rest}
                    />
                  );
                case FlowStepType.ChartElement: {
                  return (
                    <ChartElement
                      definition={element as TChartElement}
                      key={`chart-element-${element.name}`}
                      setActiveElement={
                        flowActiveElementConditionalOverrideEnabled
                          ? elementId => {
                              // set our active element
                              setActiveElement(elementId);

                              // set our parent active element
                              // we need to set the parent value because of the way conditional flows
                              // can be wrapping the relevant chart values
                              if (setParentActiveElement) setParentActiveElement(flow.id);
                            }
                          : undefined
                      }
                      clearActiveElement={
                        // only the active element can clear itself
                        // activeElementOverride considers the feature flag
                        activeElementOverride
                          ? () => {
                              setActiveElement(undefined);
                              if (setParentActiveElement) setParentActiveElement(undefined);
                            }
                          : undefined
                      }
                    />
                  );
                }
                default:
                  return undefined;
              }
            })}

            {canAddFlowsAfter && (
              <FlowSearch flows={selectableFlows} onSubmit={onAddFlow} parentFlowIndex={position} />
            )}
          </div>
        </section>
      </RequiredChartPropertiesContextProvider>
    </ChartContextProvider>
  );
};
