import { Theme, DialogProps } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import { Styles } from '@material-ui/core/styles/withStyles';
import { observer } from 'mobx-react';
import React from 'react';

import { colors } from 'src/util/colors';

const ConfirmDialog: React.FC<{
  cancelLabel?: string;
  classes: {
    buttonDanger: string;
  };
  container?: React.ReactInstance | (() => React.ReactInstance);
  disabled?: boolean;
  isDestructive?: boolean;
  maxWidth?: DialogProps['maxWidth'];
  onCancel: () => void;
  onSubmit: () => void;
  submitLabel?: string;
  title?: string;
  requireSelection?: boolean;
}> = ({
  cancelLabel = 'Cancel',
  children,
  classes,
  container,
  disabled,
  isDestructive,
  maxWidth = 'md',
  onCancel,
  onSubmit,
  submitLabel = 'Confirm',
  title = 'Are You Sure?',
  requireSelection = false,
}) => (
  <Dialog
    container={container}
    open
    maxWidth={maxWidth}
    fullWidth
    onClose={requireSelection ? undefined : onCancel}
  >
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>{children}</DialogContent>
    <DialogActions>
      {onCancel && (
        <Button variant="text" size="small" onClick={onCancel} data-testid="confirm-dialog-cancel">
          {cancelLabel}
        </Button>
      )}
      {onSubmit && (
        <Button
          className={isDestructive ? classes.buttonDanger : undefined}
          variant="contained"
          color={isDestructive ? 'default' : 'primary'}
          size="small"
          onClick={onSubmit}
          disabled={disabled}
          data-testid="confirm-dialog-submit"
        >
          {submitLabel}
        </Button>
      )}
    </DialogActions>
  </Dialog>
);

const styles: Styles<Theme, any> = () => ({
  buttonDanger: {
    backgroundColor: colors.error,
    color: 'white',
  },
});

export default withStyles(styles)(observer(ConfirmDialog));
