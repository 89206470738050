import { Close, Colorize, FileCopy, LocationOn, Notes, WarningAmber } from '@mui/icons-material';
import { IconPillOff } from '@tabler/icons-react';

export const BoulderIconMap = {
  copy: FileCopy,
  delete: Close,
  drugScreening: Colorize,
  location: LocationOn,
  notes: Notes,
  rxRunOut: IconPillOff,
  warning: WarningAmber,
};

export type BoulderIconName = keyof typeof BoulderIconMap;
