/**
 * Data store for the resourceCreate and resourceEdit pages.
 */
import { types, flow, getEnv, addMiddleware, getRoot, clone } from 'mobx-state-tree';

import createListModel from 'src/shared/stores/createListModel';
import { Provider } from 'src/shared/stores/resource';
import Calendar from 'src/stores/calendar';
import { ADD_MONITORED_PROVIDER } from 'src/stores/mutations/providers';
import { LOAD_PROVIDER, LOAD_PROVIDERS } from 'src/stores/queries/providers';

const ProvidersList = createListModel('ProvidersList', Provider, LOAD_PROVIDERS);

export default types
  .model('Providers', {
    list: types.optional(ProvidersList, { orderBy: 'lastName' }),
    provider: types.maybeNull(Provider),
    calendar: types.optional(Calendar, {}),
  })
  .actions(self => ({
    afterAttach() {
      addMiddleware(getRoot(self).events, (call, next) => {
        if (call.name === 'eventChanged') {
          self.reload();
        }
        next(call);
      });
    },
    createProvider() {
      self.provider = Provider.create();
    },
    reload() {
      if (self.provider) {
        self.load(self.provider.id, true);
      }
    },
    load: flow(function* load(id, forceLoad) {
      const providerChanged = !self.provider || self.provider.id !== id;

      // When switching tabs, don't reload the same provider.
      if (!providerChanged && !forceLoad) {
        return;
      }

      const results = yield getEnv(self).apolloClient.query({
        query: LOAD_PROVIDER,
        variables: { id },
      });
      self.provider = results.data.provider;

      if (providerChanged) {
        self.calendar.userId = id;
      }
    }),
    saveProvider(provider) {
      if (provider.id) {
        const before = clone(self.provider);
        self.provider.update(provider);
        return getEnv(self).crudService.update('Provider', self.provider, before);
      } else {
        return getEnv(self).crudService.create('Provider', provider);
      }
    },
    monitorProvider: flow(function* monitorProvider(provider) {
      const result = yield getEnv(self).apolloClient.mutate({
        mutation: ADD_MONITORED_PROVIDER,
        variables: { id: provider.id },
      });
      return result.data.monitorProvider;
    }),
  }));
