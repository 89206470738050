import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Theme, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import type { ClassNameMap, Styles } from '@material-ui/styles';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import type { MouseEventHandler } from 'react';
import { Rnd } from 'react-rnd';

// See all available properties for Rnd here:
// https://github.com/bokuweb/react-rnd#props
const FlexibleBox: React.FC<{
  cancel: string;
  children: React.ReactElement;
  classes: ClassNameMap;
  onClose?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  heading: string | React.ReactElement;
  initialHeight: number;
  initialX: number;
  initialY: number;
  width: number;
}> = ({
  cancel,
  children,
  classes,
  onClose,
  heading,
  initialHeight,
  initialX,
  initialY,
  width,
  ...props
}) => {
  const [x, setX] = useState(initialX);
  const [y, setY] = useState(initialY + window.scrollY);

  const enableResizing = {
    bottom: true,
    bottomLeft: false,
    bottomRight: false,
    left: false,
    right: false,
    top: false,
    topLeft: false,
    topRight: false,
  };

  const debouncedResizeListener = debounce(() => {
    const maxX = document.body.clientWidth - 600;
    if (x > maxX) {
      setX(maxX);
    }
  }, 100);

  useEffect(() => {
    window.addEventListener('resize', debouncedResizeListener);
    return function cleanup() {
      window.removeEventListener('resize', debouncedResizeListener);
    };
  });

  return (
    <Rnd
      bounds="body"
      style={{
        position: 'fixed',
        // Top Nav Z-Index is 1201 from Material UI.
        // If other uses of this component don't want to be on top, make this indirectly controllable via a prop
        zIndex: 1202,
      }}
      cancel={cancel}
      className={classes.container}
      position={{ x, y }}
      onDragStop={(event, draggableData) => {
        if (x !== draggableData.x) setX(draggableData.x);
        if (y !== draggableData.y) setY(draggableData.y);
      }}
      default={{
        x,
        y,
        width,
        height: initialHeight,
      }}
      minHeight={200}
      minWidth={300}
      enableResizing={enableResizing}
      onClick={() => {
        const maxX = document.body.clientWidth;
        if (x > maxX) {
          setX(maxX);
        }
      }}
    >
      <Paper
        className={classNames({
          [classes.paper]: true,
          [classes.scrollAll]: true,
          [classes.scrollHorizontallyOnly]: false,
          [classes.scrollVerticallyOnly]: true,
        })}
        elevation={3}
      >
        {(heading || onClose) && (
          <div className={classes.header}>
            <Typography variant="h6" className={classes.heading}>
              {heading}
            </Typography>
            {onClose && (
              <IconButton color="inherit" onClick={onClose} data-testid="flexbox-close">
                <CloseIcon />
              </IconButton>
            )}
          </div>
        )}
        <div className={classes.childWrapper} data-testid={props['data-testid']}>
          {children}
        </div>
      </Paper>
    </Rnd>
  );
};

const styles: Styles<Theme, any> = () => ({
  childWrapper: {
    position: 'relative' as const,
    top: 68, // Fixed heading is 48 height + 20 padding
  },
  container: {
    display: 'flex',
    zIndex: 100,
  },
  header: {
    backgroundColor: 'white',
    display: 'flex', // push close button to top right
    left: 0,
    justifyContent: 'space-between', // push close button to top right
    alignItems: 'center',
    padding: 20,
    position: 'fixed' as const,
    top: 0,
    width: '100%',
    boxSizing: 'border-box',
    zIndex: 1, // let children scroll under
  },
  heading: {
    flexGrow: 1,
  },
  paper: {
    height: '100%', // Allows resizing grips to be findable correctly
    width: '100%', // Allows resizing grips to be findable correctly
  },
  scrollAll: {
    overflow: 'scroll',
  },
  scrollHorizontallyOnly: {
    overflowX: 'scroll' as const,
  },
  scrollVerticallyOnly: {
    overflowY: 'scroll' as const,
  },
});

export default withStyles(styles)(observer(FlexibleBox));
