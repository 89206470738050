import GraphqlAdapter from 'src/data/types/GraphqlAdapter';
import { LOAD_PROVIDERS_BY_NPI, ProviderNpiLookupResult } from 'src/stores/queries/providers';
import { LOOKUP_PROVIDERS, ProviderLookupResult } from 'src/stores/queries/userSearch';

/**
 * Service for retrieving data about providers
 */
export default class ProviderDataService {
  constructor(private graphql: GraphqlAdapter) {}

  /**
   * Searches for providers using the provided query string
   *
   * @param query The string to use to search.
   */
  async search(query: string): Promise<ProviderLookupResult[]> {
    if (!query) {
      return [];
    }
    const {
      data: { providerLookup: items },
    } = await this.graphql.query<{ providerLookup: ProviderLookupResult[] }>({
      query: LOOKUP_PROVIDERS,
      variables: { q: query },
    });

    return items;
  }

  /**
   * Searches for providers using an array of NPIs.
   *
   * @param npis The provider NPIs by which to search
   */
  async getProvidersByNpi(npis: string[]): Promise<ProviderNpiLookupResult[]> {
    const {
      data: { providers },
    } = await this.graphql.query<{ providers: ProviderNpiLookupResult[] }>({
      query: LOAD_PROVIDERS_BY_NPI,
      variables: { where: { npi_in: npis } },
    });

    return providers;
  }
}
