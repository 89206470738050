import type { RawDraftContentBlock, RawDraftContentState, ContentBlock } from 'draft-js';
import { EditorState, convertToRaw, convertFromRaw, CompositeDecorator } from 'draft-js';

import { HighlightedText } from 'src/util/HighlightedText';

export type TaggedTextValue = {
  text: string | null;
  tags: string[];
};

function rawContentToTaggedText(raw: RawDraftContentState): TaggedTextValue {
  const { blocks, entityMap } = raw;
  const text = blocks.some(block => block.text.trim())
    ? blocks.map(block => block.text).join('\n')
    : '';

  return {
    text: text || '',
    tags: Object.values(entityMap).map(entity => entity.data.option),
  };
}

function taggedTextToRawContent(value?: TaggedTextValue): RawDraftContentState {
  const text = value?.text || ''; // Don't just destructure, since we want empty string in the case of `null` text too
  const tags = value?.tags || [];
  const entityMap = {};
  const blocks: RawDraftContentBlock[] = text.split('\n').map(paragraph => ({
    text: paragraph,
    entityRanges: [],
    key: '',
    type: 'unstyled',
    depth: 0,
    inlineStyleRanges: [],
  }));

  // create entities for each tag, and then find the
  // tags in each block so we can create entity ranges
  tags
    .filter(tag => tag)
    .forEach((tag, tagKey) => {
      entityMap[tagKey] = {
        type: 'TAG',
        mutability: 'IMMUTABLE',
        data: {
          option: tag,
        },
      };
      blocks.forEach(block => {
        const { text: blockText } = block;
        let tagStart = blockText.indexOf(tag);
        while (tagStart !== -1) {
          block.entityRanges.push({
            offset: tagStart,
            length: tag.length,
            key: tagKey,
          });
          tagStart = blockText.indexOf(tag, tagStart + tag.length);
        }
      });
    });

  return { blocks, entityMap };
}

// NOTE: the types on these exported functions don't quite match the function implementation. These
// functions are called indiscriminately by the old autoforms code, so the parameters' types are
// only guidelines; we can't assume they'll actually have that type.

export function draftJsToTaggedText(editorState: EditorState): TaggedTextValue {
  if (editorState instanceof EditorState) {
    return rawContentToTaggedText(convertToRaw(editorState.getCurrentContent()));
  } else {
    return editorState;
  }
}

export function taggedTextToDraftJs(modelState?: TaggedTextValue): EditorState {
  if (modelState instanceof EditorState) {
    return modelState;
  }
  const raw = taggedTextToRawContent(modelState);
  const content = convertFromRaw(raw);

  return EditorState.createWithContent(content, highlightDecorator);
}

export function taggedTextUpdateDraftJs(
  taggedText: TaggedTextValue,
  modelState: EditorState,
): EditorState {
  const raw = taggedTextToRawContent(taggedText);
  const content = convertFromRaw(raw);
  return EditorState.push(modelState, content, 'undo');
}

// Find the highlighted text in the content block:
// any text wrapped in <> will be highlighted

const findHighlightedText = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
) => {
  const text = contentBlock.getText();
  const regex = /<([^>]+)>/g;
  let matchArr = regex.exec(text);
  while (matchArr !== null) {
    const start = matchArr.index;
    const end = start + matchArr[0].length;
    callback(start, end);
    matchArr = regex.exec(text);
  }
};

const highlightDecorator = new CompositeDecorator([
  {
    strategy: findHighlightedText,
    component: HighlightedText,
  },
]);
