import React from 'react';

// Custom component to apply highlight style
export const HighlightedText = props => {
  const style = {
    backgroundColor: '#faed27', // Highlight color
    fontWeight: 'bold',
  };
  return <span style={style}>{props.children}</span>;
};
