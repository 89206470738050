import { makeStyles } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import React from 'react';

import { useDidUpdateEffect } from 'src/nightingale//hooks/useDidUpdateEffect';
import { Incrementor } from 'src/nightingale/components/ChartProperty/controls/Integer/Incrementor';
import { LabeledFormControl } from 'src/nightingale/components/ChartProperty/controls/LabeledFormControl/LabeledFormControl';
import { TextInput } from 'src/nightingale/components/ChartProperty/controls/Text/TextInput';
import { useControlState } from 'src/nightingale/hooks/useControlState';
import { useShowValidationError } from 'src/nightingale/hooks/useShowValidationError';
import { useValidationForPropertyType } from 'src/nightingale/hooks/useValidation';
import {
  ControlProps,
  IntegerChartProperty,
  ChartPropertyTypes,
} from 'src/nightingale/types/types';

/**
 * Using string type for integer input
 * @see {@link https://next.material-ui.com/components/text-fields/#type-quot-number-quot}
 * @see {@link https://technology.blog.gov.uk/2020/02/24/why-the-gov-uk-design-system-team-changed-the-input-type-for-numbers/}
 *
 * Inputs of type="number" have potential usability issues:
 * - Allowing certain non-numeric characters ('e', '+', '-', '.') and silently discarding others
 * - The functionality of scrolling to increment/decrement the number can cause accidental and hard-to-notice changes
 * - For number validation, one viable alternative is to use the default input type="text" with the pattern attribute
 */
const filterNonNumeric = (newValue: string) => newValue.replace(/[^0-9]/g, '');

/**
 * Return an on change handler for given min max values
 * will keep a value within specified bounds
 */
const enforceMinMax = (min: number, max: number) => (newValue: string) => {
  const parsedNewValue = parseInt(newValue, 10);
  if (Number.isNaN(parsedNewValue)) {
    return newValue;
  }
  if (isNumber(min) && parsedNewValue < min) {
    return min.toString();
  }
  if (isNumber(max) && parsedNewValue > max) {
    return max.toString();
  }
  return newValue;
};

/**
 * Helper function to layer on change logic
 * accepts callback and list of on change handlers
 */
const integerOnChange =
  (callback: (value: string) => any, filters: Array<(nextValue: string) => string>) =>
  (newValue: string) => {
    let value = newValue;
    filters.forEach(filter => {
      if (filter) {
        value = filter(value);
      }
    });
    callback(value);
  };

/**
 * Keypress handler for up arrow / down arrow incrementing
 */
const handleKeyPress = (callback: (value: string) => any) => event => {
  const value = parseInt(event.target.value, 10);
  if (event.key === 'ArrowDown') {
    if (value) {
      callback(String(value - 1));
    } else {
      callback('0');
    }

    return;
  }
  if (event.key === 'ArrowUp') {
    if (value) {
      callback(String(value + 1));
    } else {
      callback('1');
    }
  }
};

/**
 * Styles
 */
const useStyles = makeStyles({
  container: {
    marginTop: 5,
  },
});

/**
 * Data model
 */
export type IntegerControlProps = ControlProps<IntegerChartProperty> & {
  disabled?: boolean;
  id?: string;
};

/**
 * Integer Control component.
 */
export const IntegerControl: React.FC<IntegerControlProps> = ({
  description,
  disabled,
  label,
  max,
  min,
  onChangeValue,
  onError,
  readOnly,
  value,
  ...rest
}) => {
  const styles = useStyles();

  const [internalValue, setInternalValue] = useControlState(isNil(value) ? '' : String(value));
  useDidUpdateEffect(() => {
    if (onChangeValue) {
      const newValue = parseInt(internalValue, 10);
      onChangeValue(Number.isNaN(newValue) ? null : newValue);
    }
  }, [internalValue]);

  const validationError = useValidationForPropertyType(internalValue, ChartPropertyTypes.Integer, {
    min,
    max,
  });
  const [showError, setIsTouched, setHasSeenErrors] = useShowValidationError(value);
  useDidUpdateEffect(() => {
    const hasValidationError = !!validationError?.errors?.length;
    if (hasValidationError) setHasSeenErrors(true);
    if (onError) onError(hasValidationError);
  }, [validationError]);

  const hasError = showError && !!validationError?.errors?.length;
  const errorMessage = showError ? validationError?.message : '';

  return (
    <LabeledFormControl
      error={hasError}
      errorMessage={errorMessage}
      htmlFor={rest.id}
      label={label}
    >
      <div className={styles.container}>
        <TextInput
          autoComplete="off"
          data-testid="integer-control"
          disabled={disabled}
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
            onKeyDown: !(readOnly || disabled)
              ? handleKeyPress(integerOnChange(setInternalValue, [enforceMinMax(min, max)]))
              : undefined,
          }}
          onBlur={() => setIsTouched(true)}
          onChange={integerOnChange(setInternalValue, [filterNonNumeric])}
          placeholder={description}
          readOnly={readOnly}
          startAdornment={
            <InputAdornment position="start">
              <Incrementor
                setValue={integerOnChange(setInternalValue, [enforceMinMax(min, max)])}
                value={parseInt(internalValue, 10)}
                readOnly={readOnly}
                disabled={disabled}
              />
            </InputAdornment>
          }
          value={internalValue}
          {...rest}
        />
      </div>
    </LabeledFormControl>
  );
};
