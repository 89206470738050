import { ErrorBoundary as SentryErrorBoundary, Scope } from '@sentry/react';
import React from 'react';

import ErrorAlert from 'src/components/general/ErrorAlert';
import type { ChartElement } from 'src/nightingale/types/types';

const ChartElementErrorMessage: React.FC<{ elementName: string; error: Error }> = ({
  elementName,
  error,
}) => (
  <ErrorAlert
    title={`An error occurred in this element (${elementName})`}
    message={error.message}
  />
);

export const ChartElementErrorBoundary: React.FC<{ definition?: ChartElement }> = ({
  children,
  definition,
}) => {
  const name = definition?.name ?? '(Unknown element)';
  const label = definition?.label ?? name;

  return (
    <SentryErrorBoundary
      fallback={({ error }) => (
        <ChartElementErrorMessage error={error as Error} elementName={label} />
      )}
      beforeCapture={(scope: Scope) => scope.setTag('chartElementName', name)}
    >
      {children}
    </SentryErrorBoundary>
  );
};
