import { Theme, makeStyles } from '@material-ui/core';
import { Editor } from 'draft-js';
import type { EditorState } from 'draft-js';
import Autocomplete from 'draft-js-autocomplete';
import 'draft-js/dist/Draft.css';
import difference from 'lodash/difference';
import isEqual from 'lodash/isEqual';
import React, { useState, useMemo, useRef, useEffect } from 'react';

import Colors from 'src/nightingale/Colors';
import { LabeledFormControl } from 'src/nightingale/components/ChartProperty/controls/LabeledFormControl/LabeledFormControl';
import { createHashTagAutocomplete } from 'src/nightingale/components/ChartProperty/controls/TaggedText/TaggedTextControl.utils';
import type { ControlProps, TaggedTextChartProperty } from 'src/nightingale/types/types';
import {
  draftJsToTaggedText,
  taggedTextToDraftJs,
  taggedTextUpdateDraftJs,
} from 'src/util/taggedText';

export interface TaggedTextControlProps extends ControlProps<TaggedTextChartProperty> {
  tags: string[];
}

/**
 * TaggedText Control component. Essentially a multi line text control but
 * allows search/autocomplete among ChartProperty's tags by typing '#'.
 */
export const TaggedTextControl: React.FC<ControlProps<TaggedTextChartProperty>> = ({
  autoFocus,
  disabled,
  hasEmptyValue,
  isRequired,
  label,
  onChangeValue,
  value,
  tags,
}) => {
  const [editorState, setEditorState] = useState(taggedTextToDraftJs(value));
  useEffect(() => {
    // Keep up with changes made to the value from outside this component (namely the history modal).
    // But first, check if the values are equal, which prevents circular logic where:
    // * User modifies `editorState`
    // * That propagates out via `onChangeValue`
    // * We get re-rendered with a new `value` and re-render the input control, interrupting the
    //   editing experience
    if (!value) return;
    const uniformValue = draftJsToTaggedText(taggedTextToDraftJs(value));
    if (!isEqual(uniformValue, draftJsToTaggedText(editorState))) {
      setEditorState(taggedTextUpdateDraftJs(uniformValue, editorState));
    }
  }, [value]);

  const styles = useStyles({ disabled });
  const editorRef = useRef<Editor>(null);
  useEffect(() => {
    const frame = requestAnimationFrame(() => {
      if (autoFocus && editorRef.current) editorRef.current.focus();
    });
    return () => cancelAnimationFrame(frame);
  }, [autoFocus, editorRef]);

  const changeHandler = (newState: EditorState) => {
    const newValue = draftJsToTaggedText(newState);
    const oldValue = draftJsToTaggedText(editorState);

    // prevents tagged state for less confusing UX
    newValue.tags = [];

    setEditorState(newState);
    if (!isEqual(oldValue, newValue)) {
      if (onChangeValue) onChangeValue(newValue);
    }
  };

  const hashTagAutocomplete = useMemo(() => {
    const usedTags = value?.tags || [];
    return createHashTagAutocomplete(difference(tags, usedTags));
  }, [tags, value?.tags]);

  return (
    <LabeledFormControl hasEmptyValue={hasEmptyValue} isRequired={isRequired} label={label}>
      <div className={styles.editor}>
        <Autocomplete
          editorState={editorState}
          onChange={changeHandler}
          autocompletes={[hashTagAutocomplete]}
          readOnly={disabled}
        >
          <Editor
            editorState={editorState}
            onChange={changeHandler}
            placeholder="Type # for templates…"
            ref={editorRef}
            readOnly={disabled}
          />
        </Autocomplete>
      </div>
    </LabeledFormControl>
  );
};

const useStyles = makeStyles<Theme, { disabled?: boolean }>(theme => ({
  /*
    draft-js's text-editor is a <div contentEditable=true>, not an <input>, so the input styling
    that we usually get from material-ui doesn't apply. Thus we copy mui's Input styles from
    https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui/src/Input/Input.js#L34
    and apply them to the editor, with nightingale-specific styles mixed in.
  */
  editor: {
    fontFamily: '"Nunito", "Nunito Sans"',
    lineHeight: '145%',
    paddingTop: 6,
    paddingBottom: 7,
    backgroundColor: ({ disabled }) => (disabled ? Colors.Gray1 : 'transparent'),
    cursor: ({ disabled }) => (disabled ? 'not-allowed' : 'default'),

    '&, & span': {
      color: ({ disabled }) => (disabled ? Colors.Gray6 : 'inherit'),
    },
    '& .MuiMenuItem-root span': {
      whiteSpace: 'normal',
    },
    '& .public-DraftEditorPlaceholder-root': {
      color: Colors.Gray4,
      fontStyle: 'italic',
    },
    '&::after': {
      left: 0,
      bottom: 0,
      // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
      content: '""',
      position: 'absolute',
      right: 0,
      transform: 'scaleX(0)',
      borderBottomColor: ({ disabled }) => (disabled ? 'transparent' : Colors.BlueSpruce),
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shorter,
        easing: theme.transitions.easing.easeOut,
      }),
      pointerEvents: 'none', // Transparent to the hover style.
    },
    '&:focus-within::after': {
      transform: 'scaleX(1)',
    },
    '&::before': {
      borderBottomColor: Colors.Gray3,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
      left: 0,
      bottom: 0,
      content: '"\\00a0"',
      position: 'absolute',
      right: 0,
      transition: theme.transitions.create('border-bottom-color', {
        duration: theme.transitions.duration.shorter,
      }),
      pointerEvents: 'none', // Transparent to the hover style.
    },
    '&:hover::before': {
      borderBottomWidth: 1,
      borderBottomColor: ({ disabled }) => (disabled ? Colors.Gray3 : Colors.BlueSpruce),
    },
    '&:hover::after': {
      borderBottomWidth: 2,
      borderBottomColor: ({ disabled }) => (disabled ? 'transparent' : Colors.BlueSpruce),
    },
    /*
      These last directives may not have any effect. They're included because they were in the MUI
      input styles, and your humble author didn't know how to test their validity. It's unclear how
      $error or $disabled could be set on a non-MUI element, though.
    */
    '&$error::after': {
      borderBottomColor: Colors.Coral,
      transform: 'scaleX(1)',
    },
  },
}));
