/**
 * Generates a random integer between min and max
 */
function getRandomInt(min, max) {
  // Create byte array and fill with 1 random number
  const byteArray = new Uint8Array(1);
  window.crypto.getRandomValues(byteArray);

  const range = max - min + 1;
  const maxRange = 256;
  if (byteArray[0] >= Math.floor(maxRange / range) * range) return getRandomInt(min, max);
  return min + (byteArray[0] % range);
}

/**
 * Generates a random series of secure numbers as a string
 * @param numDigits the number of digits to generate
 * @returns a string of random digits
 */
export function randomDigits(numDigits: number) {
  if (numDigits <= 0) {
    return '';
  }
  return [...Array(numDigits)].map(() => getRandomInt(0, 9)).join('');
}
