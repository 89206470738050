import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { ApolloClientContext } from 'src/data/ApolloClientContext';
import PatientDataService from 'src/data/patients/PatientDataService';
import { DismissableBannerComponent } from 'src/duplicateCharts/DismissableBannerComponent';
import PossibleDuplicateChartsArgs from 'src/duplicateCharts/PossibleDuplicateChartsArgs';
import {
  OneDuplicateMessage,
  MoreThanOneDuplicateMessage,
} from 'src/duplicateCharts/PossibleDuplicateChartsBannerMessages';
import { findExactNameAndDobMatch } from 'src/duplicateCharts/possibleDuplicateChartsLookups';
import { PatientLookupResult } from 'src/stores/queries/userSearch';

const PossibleDuplicateChartsBanner: React.FC<
  PossibleDuplicateChartsArgs
> = possibleDuplicateChartsArgs => {
  const classes = useStyles();

  const [possibleDuplicates, setPossibleDuplicates] = useState<PatientLookupResult[]>([]);

  const { apolloClient } = useContext(ApolloClientContext);

  const checkForPossibleDuplicateCharts = useCallback(async (): Promise<void> => {
    if (!apolloClient) {
      return;
    }

    const patientDataService = new PatientDataService(apolloClient);

    setPossibleDuplicates([
      ...(await findExactNameAndDobMatch({
        possibleDuplicateChartsArgs,
        patientDataService,
      })),
      // additional duplicate check lookups can be spread here
    ]);
  }, [apolloClient, possibleDuplicateChartsArgs]);

  useEffect(() => {
    checkForPossibleDuplicateCharts();
  }, [checkForPossibleDuplicateCharts]);

  const hasPossibleDuplicates = possibleDuplicates.length > 0;

  // coerced args can override type, handle null checks here
  const { firstName, lastName, dob } = possibleDuplicateChartsArgs;
  if (!firstName || !lastName || !dob) {
    // if any of the required fields are missing, don't attempt to find duplicates
    return null;
  }

  if (!apolloClient) {
    return null;
  }

  return (
    <DismissableBannerComponent className={classes.snackbar} open={hasPossibleDuplicates}>
      {hasPossibleDuplicates && (
        <Typography data-testid="possible-duplicate-charts-banner" className={classes.bannerText}>
          <OneDuplicateMessage possibleDuplicates={possibleDuplicates} />
          <MoreThanOneDuplicateMessage possibleDuplicates={possibleDuplicates} />
        </Typography>
      )}
    </DismissableBannerComponent>
  );
};

export default PossibleDuplicateChartsBanner;

const useStyles = makeStyles({
  bannerText: {
    fontSize: 'inherit',
  },
  snackbar: {
    marginTop: 52,
  },
});
