import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import DescriptionOutlined from '@material-ui/icons/DescriptionOutlined';
import WavingIcon from '@material-ui/icons/EmojiPeople';
import LanguageIcon from '@material-ui/icons/Language';
import LocalPharmacyIcon from '@material-ui/icons/LocalPharmacy';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import { inject, observer } from 'mobx-react';
import React, { useContext } from 'react';

import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import { RouterLinkDiv } from 'src/components/general/routerLink';
import {
  DISPLAY_PHARMACY_RECOMMENDATIONS,
  HIDE_INQUIRIES,
  RESTRICT_QUEUE_PAGE_ACCESS,
} from 'src/featureFlags/currentFlags';
import { PharmacySvgIcon } from 'src/pharmacyRecommendations/PharmacyIcon';
import type { Provider, Provider as ProviderInstance } from 'src/shared/stores/resource';
import type { RootStore } from 'src/stores/root';
import { ProviderRole } from 'src/stores/users/userType';
import { patientsQueryParamsForUser, claimsQueryParams } from 'src/util/nav';

const EXTERNAL_LINKS = [
  {
    label: 'Help Desk',
    url: 'https://helpdesk.boulder.care',
  },
  {
    label: 'Provider Resources',
    url: 'https://www.notion.so/boulder/Provider-Team-ed1b62a0f91a480189a2f6b89d091b96',
  },
];

const AdminSidebar = ({ rootStore: { auth } }: { rootStore: RootStore }) => {
  const flags = useContext(FeatureFlagContext);
  const hideInquiries = !!flags[HIDE_INQUIRIES];
  const restrictQueuePageAccess = !!flags[RESTRICT_QUEUE_PAGE_ACCESS];
  const displayPharmacyRecommendations = !!flags[DISPLAY_PHARMACY_RECOMMENDATIONS];
  const isClinician = (auth.user as Provider)?.teamRole === ProviderRole.Clinician;
  const showQueuePage = !(restrictQueuePageAccess && isClinician);
  return (
    <div>
      <List>
        <ListItem
          button
          component={RouterLinkDiv}
          routeName="patients"
          queryParams={patientsQueryParamsForUser(auth.user as ProviderInstance)}
        >
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Patients" />
        </ListItem>
        <ListItem button component={RouterLinkDiv} routeName="providers">
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="Providers" />
        </ListItem>
        <ListItem
          button
          component={RouterLinkDiv}
          routeName="showProvider"
          params={{ id: auth.user?.id || '' }}
        >
          <ListItemIcon>
            <CalendarIcon />
          </ListItemIcon>
          <ListItemText primary="My Calendar" />
        </ListItem>
        {showQueuePage && (
          <ListItem button component={RouterLinkDiv} routeName="dropInClinicRequests">
            <ListItemIcon>
              <WavingIcon />
            </ListItemIcon>
            <ListItemText primary="Pop-In Queue" />
          </ListItem>
        )}
        {!hideInquiries && (
          <ListItem button component={RouterLinkDiv} routeName="inquiries">
            <ListItemIcon>
              <AssignmentIndIcon />
            </ListItemIcon>
            <ListItemText primary="Inquiries" />
          </ListItem>
        )}
        <ListItem
          button
          component={RouterLinkDiv}
          routeName="claims"
          queryParams={claimsQueryParams()}
        >
          <ListItemIcon>
            <DescriptionOutlined />
          </ListItemIcon>
          <ListItemText primary="Claims" />
        </ListItem>
        <ListItem button component={RouterLinkDiv} routeName="erx">
          <ListItemIcon>
            <LocalPharmacyIcon />
          </ListItemIcon>
          <ListItemText primary="DoseSpot" />
        </ListItem>
        {displayPharmacyRecommendations && (
          <ListItem button component={RouterLinkDiv} routeName="pharmacyRecommendations">
            <ListItemIcon>
              <PharmacySvgIcon />
            </ListItemIcon>
            <ListItemText primary="Pharmacy Recommendations" />
          </ListItem>
        )}
        <Divider />
        {EXTERNAL_LINKS.map(link => (
          <ListItem key={link.url} button onClick={() => window.open(link.url, '_blank')}>
            <ListItemIcon>
              <LanguageIcon />
            </ListItemIcon>
            <ListItemText primary={link.label} />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default inject('rootStore')(observer(AdminSidebar));
