import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import {
  EventNote,
  EventNoteOutlined,
  ViewAgenda,
  ViewAgendaOutlined,
  CalendarToday,
  CalendarTodayOutlined,
  Note,
  NoteOutlined,
  List,
  ListOutlined,
  CheckBox,
  CheckBoxOutlined,
  LocalPharmacy,
  LocalPharmacyOutlined,
  CameraAltOutlined,
  CameraAlt,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';

import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import { LOAD_MENU_PATIENT } from 'src/components/general/PatientMenu/queries.gql';
import useTypedSWR from 'src/components/general/useTypedSWR';
import { LastPatientVisitContext } from 'src/events/LastPatientVisitContext';
import {
  CLINICAL_SNAPSHOT,
  POC_TESTING,
  SHOW_PATIENT_PEBBLES_TABLE,
} from 'src/featureFlags/currentFlags';
import Colors from 'src/nightingale/Colors';
import PatientMenuTab from 'src/patientMenu/components/PatientMenuTab';
import PatientMenuTabValue from 'src/patientMenu/components/PatientMenuTabValue';
import {
  ClinicTabIcon,
  ClinicTabIconOutlined,
  ZendeskTabIcon,
  PocTestingTabIcon,
  PocTestingTabIconOutlined,
} from 'src/patientMenu/icons';
import PatientPebblesCountBadge from 'src/pebbles/components/PatientPebblesCountBadge';
import PatientPebblesCountProvider from 'src/pebbles/components/PatientPebblesCountContext/PatientPebblesCountProvider';
import { getStartTime } from 'src/shared/util/events';
import type { RootStore } from 'src/stores/root';
import { inject } from 'src/util/inject';
import { PEBBLE_STATUS_MAP } from 'src/util/pebbles';

const PatientMenu = ({
  patientId,
  activeTab,
  activeEventId,
  rootStore: {
    generateRouteUrl,
    patients: { calendar },
  },
}: {
  patientId: string;
  activeTab: string;
  activeEventId?: string;
  rootStore: RootStore;
}) => {
  const { data } = useTypedSWR([LOAD_MENU_PATIENT, { id: patientId }], {
    revalidateOnFocus: false,
  });
  const { lastPatientVisits } = useContext(LastPatientVisitContext);
  const flags = useContext(FeatureFlagContext);

  const patient = data?.menuPatient ?? undefined;

  const activeTabValue =
    activeTab === PatientMenuTabValue.Events && activeEventId ? activeEventId : activeTab;

  const classes = useStyles();

  return (
    <nav className={classes.container} data-testid="participant-menu-container">
      {patient?.id && (
        <Paper className={classes.content} elevation={2}>
          <aside className={classes.logo}>
            <img className={classes.boulderIcon} src="/boulderIcon.png" alt="Boulder" />
          </aside>
          <Tabs
            className={classes.tabs}
            TabIndicatorProps={{
              style: { display: 'none' },
            }}
            orientation="vertical"
            variant="scrollable"
            value={activeTabValue}
          >
            <PatientMenuTab
              value={PatientMenuTabValue.Overview}
              patientId={patient.id}
              activeTab={activeTabValue}
              ActiveIcon={EventNote}
              InactiveIcon={EventNoteOutlined}
              label="Overview"
              title="Overview"
            />

            <PatientMenuTab
              hide={!flags[CLINICAL_SNAPSHOT]}
              value={PatientMenuTabValue.ClinicalSnapshot}
              patientId={patient.id}
              activeTab={activeTabValue}
              ActiveIcon={CameraAlt}
              InactiveIcon={CameraAltOutlined}
              label="Snapshot"
              title="Clinical Snapshot"
            />

            <PatientMenuTab
              value={PatientMenuTabValue.Activity}
              patientId={patient.id}
              activeTab={activeTabValue}
              ActiveIcon={ViewAgenda}
              InactiveIcon={ViewAgendaOutlined}
              label="Activity"
              title="Activity"
            />

            <PatientMenuTab
              value={PatientMenuTabValue.Calendar}
              patientId={patient.id}
              activeTab={activeTabValue}
              ActiveIcon={CalendarToday}
              InactiveIcon={CalendarTodayOutlined}
              label="Cal"
              title="Calendar"
              queryParams={{ date: calendar.currentDateQueryParam, view: calendar.currentView }}
            />

            {lastPatientVisits[patient.id]?.length > 0
              ? [...lastPatientVisits[patient.id]]
                  // sort operates on the array in-place, which we don't want, so make a copy first
                  .sort((a, b) => (a.start && b.start ? a.start.getTime() - b.start.getTime() : 0))
                  .map(lastEvent => {
                    const start = getStartTime(lastEvent);
                    const formattedDate = format(start, 'MM/dd/yy');
                    const formattedDateTime = format(lastEvent.start, 'MM/dd/yyyy h:mm a');
                    const titleDateString = lastEvent.allDay ? formattedDate : formattedDateTime;

                    return (
                      <PatientMenuTab
                        key={lastEvent.id}
                        value={PatientMenuTabValue.Events}
                        tabItem={lastEvent.id}
                        isSubTab
                        patientId={patient.id}
                        activeTab={activeTabValue}
                        label={`– ${lastEvent.start ? formattedDate : 'Visit'}`}
                        title={`– ${lastEvent.start ? titleDateString : 'Visit'}`}
                      />
                    );
                  })
              : null}

            <PatientMenuTab
              hide={!flags[POC_TESTING]}
              value={PatientMenuTabValue.PocTesting}
              patientId={patient.id}
              activeTab={activeTabValue}
              ActiveIcon={PocTestingTabIcon}
              InactiveIcon={PocTestingTabIconOutlined}
              label="POCT"
              title="POC Testing"
            />

            <PatientMenuTab
              value={PatientMenuTabValue.Documents}
              patientId={patient.id}
              activeTab={activeTabValue}
              ActiveIcon={Note}
              InactiveIcon={NoteOutlined}
              label="Docs"
              title="Documents"
            />

            <PatientMenuTab
              hide={!patient.zendeskUrl}
              value={PatientMenuTabValue.Zendesk}
              patientId={patient.id}
              activeTab={activeTabValue}
              InactiveIcon={ZendeskTabIcon}
              label="Zendesk"
              title="Zendesk"
              url={patient.zendeskUrl as string}
            />

            <PatientMenuTab
              hide={!flags[SHOW_PATIENT_PEBBLES_TABLE]}
              value={PatientMenuTabValue.Pebbles}
              patientId={patient.id}
              activeTab={activeTabValue}
              ActiveIcon={List}
              InactiveIcon={ListOutlined}
              label={
                flags[SHOW_PATIENT_PEBBLES_TABLE] ? (
                  <PatientPebblesCountProvider patientId={patientId}>
                    <PatientPebblesCountBadge />
                  </PatientPebblesCountProvider>
                ) : (
                  'Pebbles'
                )
              }
              title="Pebbles"
            />
            <PatientMenuTab
              hide={flags[SHOW_PATIENT_PEBBLES_TABLE]}
              value={PatientMenuTabValue.Pebbles}
              patientId={patient.id}
              activeTab={activeTabValue}
              InactiveIcon={ListOutlined}
              label="Pebbles"
              title="Pebbles"
              url={generateRouteUrl(
                'pebbles',
                {},
                {
                  page: 0,
                  participant: patient.id,
                  status: Object.keys(PEBBLE_STATUS_MAP).filter(
                    status => status !== 'completed' && status !== 'wont_do',
                  ),
                },
              )}
            />

            <PatientMenuTab
              value={PatientMenuTabValue.Tasks}
              patientId={patient.id}
              activeTab={activeTabValue}
              ActiveIcon={CheckBox}
              InactiveIcon={CheckBoxOutlined}
              label="Tasks"
              title="Tasks"
            />

            <PatientMenuTab
              value={PatientMenuTabValue.ClinicActions}
              patientId={patient.id}
              activeTab={activeTabValue}
              ActiveIcon={ClinicTabIcon}
              InactiveIcon={ClinicTabIconOutlined}
              label="Clinic"
              title="Clinic"
            />

            <PatientMenuTab
              value={PatientMenuTabValue.Erx}
              patientId={patient.id}
              activeTab={activeTabValue}
              ActiveIcon={LocalPharmacy}
              InactiveIcon={LocalPharmacyOutlined}
              label="e-Rx"
              title="e-Rx"
            />
          </Tabs>
        </Paper>
      )}
    </nav>
  );
};

const useStyles = makeStyles({
  container: {
    width: 138,
  },
  content: {
    height: 'calc(100vh - 64px)',
    overflowY: 'auto',
    top: 64,
    borderRadius: 0,
    paddingTop: 0,
    position: 'sticky',
  },
  logo: {
    height: 80,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // to avoid cutting into tabs component below
    borderBottom: `1px solid ${Colors.Gray3}`,
  },
  boulderIcon: {
    height: 50,
  },
  tabs: {
    width: 138,
  },
});

export default inject<typeof PatientMenu>('rootStore')(observer(PatientMenu));
