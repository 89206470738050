import {
  Card,
  CardContent,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Switch,
  makeStyles,
} from '@material-ui/core';
import type { NormalizedCacheObject } from 'apollo-cache-inmemory';
import type ApolloClient from 'apollo-client';
import camelCase from 'lodash/camelCase';
import React from 'react';

import DurationForm from 'src/dropInClinic/queueSettings/DurationForm';
import PrioritizationSettingKeyValueForm from 'src/dropInClinic/queueSettings/PrioritizationSettingKeyValueForm';
import PrioritizationSettingNumberForm from 'src/dropInClinic/queueSettings/PrioritizationSettingNumberForm';
import QueueLimitsForms from 'src/dropInClinic/queueSettings/QueueLimitsForms';
import { Schedule } from 'src/dropInClinic/queueSettings/Schedule';
import { useFormStyles } from 'src/dropInClinic/queueSettings/form.styles';
import {
  globalSettingNames,
  stateSettingNames,
} from 'src/dropInClinic/queueSettings/queueSettingNames';
import { UPDATE_SETTINGS } from 'src/dropInClinic/queueSettings/updateSettings.gql';
import { useQueueSettings } from 'src/dropInClinic/queueSettings/useQueueSettings';
import useApolloClient from 'src/shared/client/useApolloClient';

const defaultDuration = 90;
const defaultTimeInQueue = {
  // The key order here determines the rendering order
  thresholdMinutes: 0,
  bonusMinutes: 0,
};

export const ScheduledQueueSettings: React.FC = () => {
  const classes = useStyles();
  const formClasses = useFormStyles();
  const apolloClient = useApolloClient();

  const {
    activeQueueSettings,
    hasError,
    isLoading,
    mutate,
    weekdayQueueSettings,
    weekendQueueSettings,
  } = useQueueSettings();

  if (isLoading) {
    return <div className={classes.root}>Fetching queue settings</div>;
  }

  if (hasError) {
    return <div className={classes.root}>Error retrieving queue settings</div>;
  }

  return (
    <div className={classes.root}>
      <div>
        <Card>
          <CardContent>
            <div className={classes.formLabel}>Immediate Settings</div>
            <div className={formClasses.labelInstruction} style={{ marginBottom: '1rem' }}>
              These settings take effect immediately and will not be updated automatically based on
              the weekday / weekend schedule.
            </div>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    name="globalCaseRateIntakesOnly"
                    onChange={handleBooleanSubmit(Schedule.ACTIVE, apolloClient, mutate)}
                    checked={activeQueueSettings.globalCaseRateIntakesOnly?.value === 'true'}
                  />
                }
                label="Allow ONLY case rate intakes"
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    name="globalPrioritizeCaseRateFollowUps"
                    onChange={handleBooleanSubmit(Schedule.ACTIVE, apolloClient, mutate)}
                    checked={
                      activeQueueSettings.globalPrioritizeCaseRateFollowUps?.value === 'true'
                    }
                  />
                }
                label="Add priority to case rate follow-ups"
              />
              <div
                className={formClasses.labelInstruction}
                style={{ marginBottom: '1rem', marginLeft: 48 }}
              >
                Prioritize CR follow ups like CR intakes and reengagements – increases likelihood
                that CR follow up will be able to access queue
              </div>
            </FormGroup>
          </CardContent>
        </Card>
      </div>
      <div className={classes.scheduledSettingContainer}>
        <div>Active</div>
        <div>Weekday</div>
        <div>Weekend</div>

        <div className={classes.category}>
          <FormLabel className={classes.formLabel}>Global daily queue limits</FormLabel>
        </div>

        <QueueLimitsForms settingNames={globalSettingNames} onSubmit={handleSubmit} />

        {Object.entries(stateSettingNames).map(([state, stateQueueSettingNames]) => (
          <>
            <div className={classes.category}>
              <FormLabel className={classes.formLabel}>{state} daily queue limits</FormLabel>
            </div>

            <QueueLimitsForms settingNames={stateQueueSettingNames} onSubmit={handleStateSubmit} />
          </>
        ))}

        <div className={classes.category}>
          <FormLabel className={classes.formLabel}>
            Availability window durations
            <div className={formClasses.labelInstruction}>
              Patients can join the queue if we expect to see them for a visit within this many
              minutes. A higher value lets more patients join, at the cost of a longer wait.
            </div>
          </FormLabel>
        </div>

        {[Schedule.ACTIVE, Schedule.WEEKDAY, Schedule.WEEKEND].map((schedule: Schedule) => {
          const queueSettings = {
            [Schedule.ACTIVE]: activeQueueSettings,
            [Schedule.WEEKDAY]: weekdayQueueSettings,
            [Schedule.WEEKEND]: weekendQueueSettings,
          }[schedule];

          return (
            <div>
              <DurationForm
                requestType="FOLLOW_UP"
                duration={
                  (queueSettings.followUpAvailabilityWindowDuration?.value as number) ||
                  defaultDuration
                }
                onSubmit={handleAvailabilityWindowSubmit(schedule, apolloClient)}
              />
              <DurationForm
                requestType="INTAKE"
                duration={
                  (queueSettings.intakeAvailabilityWindowDuration?.value as number) ||
                  defaultDuration
                }
                onSubmit={handleAvailabilityWindowSubmit(schedule, apolloClient)}
              />
              <DurationForm
                requestType="REENGAGEMENT"
                duration={
                  (queueSettings.reengagementAvailabilityWindowDuration?.value as number) ||
                  defaultDuration
                }
                onSubmit={handleAvailabilityWindowSubmit(schedule, apolloClient)}
              />
            </div>
          );
        })}

        <div className={classes.category}>
          <FormLabel className={classes.formLabel}>
            Prioritization Settings
            <div className={formClasses.labelInstruction}>
              The Prioritization Settings affect a patient&apos;s assumed wait time in the queue. If
              a patient has a buprenorphine runout day today or tomorrow or is a case rate patient
              requesting an intake or reengagement, the associated bonus time will be added to their
              wait time when they enter the queue. A patient will be scheduled before other patients
              in the queue if their total bonus time exceeds the wait time of those other patients
              in the queue.
            </div>
          </FormLabel>
        </div>

        {[Schedule.ACTIVE, Schedule.WEEKDAY, Schedule.WEEKEND].map((schedule: Schedule) => {
          const queueSettings = {
            [Schedule.ACTIVE]: activeQueueSettings,
            [Schedule.WEEKDAY]: weekdayQueueSettings,
            [Schedule.WEEKEND]: weekendQueueSettings,
          }[schedule];

          return (
            <div>
              <PrioritizationSettingNumberForm
                name="caseRatePayorBonusTimeMinutes"
                initialValue={(queueSettings.caseRatePayorBonusTimeMinutes?.value as number) || 0}
                onSubmit={handlePrioritizationSubmit(schedule, apolloClient)}
              />
              <PrioritizationSettingNumberForm
                name="bupeRunOutBonusTimeMinutes"
                initialValue={(queueSettings.bupeRunOutBonusTimeMinutes?.value as number) || 0}
                onSubmit={handlePrioritizationSubmit(schedule, apolloClient)}
              />
              <PrioritizationSettingKeyValueForm
                name="timeInQueue"
                initialValue={
                  queueSettings.timeInQueue?.value
                    ? JSON.parse(queueSettings.timeInQueue.value as string)
                    : defaultTimeInQueue
                }
                onSubmit={handlePrioritizationSubmit(schedule, apolloClient)}
              />
              <PrioritizationSettingNumberForm
                name="preferredDayBonus"
                initialValue={(queueSettings.preferredDayBonus?.value as number) || 0}
                onSubmit={handlePrioritizationSubmit(schedule, apolloClient)}
              />
              <PrioritizationSettingNumberForm
                name="hasPreJoined"
                initialValue={(queueSettings.hasPreJoined?.value as number) || 0}
                onSubmit={handlePrioritizationSubmit(schedule, apolloClient)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
    gap: theme.spacing(3),
  },
  scheduledSettingContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: 'auto',
    gap: theme.spacing(3),
  },
  category: {
    gridColumn: '1 / span 3',
  },
  queueFields: {
    marginTop: 30,
    maxWidth: 333, // arbitrarily selected based on existing form width
  },
  formLabel: { display: 'block', marginBottom: 20 },
}));

const handleSubmit =
  (schedule: Schedule, apolloClient: ApolloClient<NormalizedCacheObject>) =>
  async (e: React.FormEvent) => {
    e.preventDefault();

    const { name } = e.target[0];
    const value = Number(e.target[0].value);
    if (!name || Number.isNaN(value)) return;

    const newSetting = {
      dataType: 'number',
      name,
      schedule,
      value: Number(e.target[0].value),
    };

    await apolloClient.mutate({ mutation: UPDATE_SETTINGS, variables: { settings: [newSetting] } });
  };

const handleBooleanSubmit =
  (schedule: Schedule, apolloClient: ApolloClient<NormalizedCacheObject>, mutate) =>
  async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const { name } = e.target;
    const value = Boolean(e.target.checked);
    if (!name) return;

    const newSetting = {
      dataType: 'boolean',
      name,
      schedule,
      value,
    };

    await apolloClient.mutate({ mutation: UPDATE_SETTINGS, variables: { settings: [newSetting] } });
    mutate();
  };

const handleStateSubmit =
  (schedule: Schedule, apolloClient: ApolloClient<NormalizedCacheObject>) =>
  async (e: React.FormEvent) => {
    e.preventDefault();

    const { name } = e.target[0];
    const value = Number(e.target[0].value);
    if (!name || Number.isNaN(value)) return;

    const newSetting = {
      dataType: 'number',
      name,
      schedule,
      value,
    };

    await apolloClient.mutate({ mutation: UPDATE_SETTINGS, variables: { settings: [newSetting] } });
  };

const handleAvailabilityWindowSubmit =
  (schedule: Schedule, apolloClient: ApolloClient<NormalizedCacheObject>) =>
  async (e: React.FormEvent) => {
    e.preventDefault();

    const requestType = camelCase(e.target[0].name.split('-')[0]);
    const value = Number(e.target[0].value);

    if (!requestType || Number.isNaN(value)) return;

    const newSetting = {
      dataType: 'number',
      name: `${requestType}AvailabilityWindowDuration`,
      schedule,
      value,
    };

    await apolloClient.mutate({ mutation: UPDATE_SETTINGS, variables: { settings: [newSetting] } });
  };

const handlePrioritizationSubmit =
  (schedule: Schedule, apolloClient: ApolloClient<NormalizedCacheObject>) =>
  async (setting: { name: string; value: number | Record<string, number> }) => {
    const newSetting = {
      dataType: typeof setting.value === 'number' ? 'number' : 'keyValue',
      name: setting.name,
      schedule,
      value: typeof setting.value === 'number' ? setting.value : JSON.stringify(setting.value),
    };

    await apolloClient.mutate({
      mutation: UPDATE_SETTINGS,
      variables: { settings: [newSetting] },
    });
  };
