import { isValid } from 'date-fns';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import isPlainObject from 'lodash/isPlainObject';
import { getSnapshot } from 'mobx-state-tree';

import { parseUnknownDate } from 'src/util/parseUnknownDate';

/**
 * Properties that should be omitted from any update requests from the front-end, e.g.
 * readonly properties, internal-only properties, etc.
 */
const omittedPropertiesByTypename = {
  Patient: ['prescriberActivity', 'peerActivity', 'coordinatorActivity'],
};

/**
 * Prepare a data object to get written to the API. OpenCRUD uses "connect" and "disconnect"
 * selectors to link/unlink related objects, so this converts an object like {name: 'Dave',
 * prescriber: {id: 'X', name: 'Amanda'] to the expected API format
 * {name: 'Dave', connect: {id: 'X'}}.
 */
export function prepareData(item, originalItemArg) {
  const originalItem = originalItemArg
    ? getSnapshot<typeof originalItemArg>(originalItemArg)
    : null;
  const omittedProperties = omittedPropertiesByTypename[originalItem?.__typename] || [];

  const ret = {};
  Object.keys(item).forEach(key => {
    if (['id', '__typename'].includes(key)) {
      // Don't include the ID, since it's not writable.
      // Don't include __typename, which can be appended to objects by the
      // update/create mutations and can cause errors. This is a known issue:
      // https://github.com/apollographql/apollo-client/issues/1564
    } else if (omittedProperties.includes(key)) {
      // Don't include properties that shouldn't be writable for this type
    } else if (originalItem && item[key] === originalItem[key]) {
      // Save a few bytes by not including fields that haven't changed.
    } else if (
      originalItem &&
      originalItem[key] &&
      isValid(parseUnknownDate(item[key])) &&
      parseUnknownDate(item[key]).toISOString() === originalItem[key]
    ) {
      // Datetime gets serialized to ISOString on Snapshot in our custom Datetime implementation
      // in src/shared/stores/resource - this is to prevent us from passing along
      // unchanged datetime fields.
    } else if (!originalItem && !item[key]) {
      // Don't include falsy values when creating a new object.
      // TODO: Do we really want to omit all falsy values? We could have meaningful falsy values
      // (e.g. 0 or false) when creating something.
    } else if (
      key === 'eventResults' &&
      item[key] &&
      item[key].length > 1 &&
      item[key][0].type === 'inquiryFormDetails' &&
      item[key][0].results?.formDocuments?.length
    ) {
      // TODO: Temporary fix to restore editing for inquiry webforms with file uploads

      // InquiryFormDetails has a `formDocuments` property containing an Array of IDs
      // that get resolved to the corresponding FormDocument when queried. This function is not
      // currently recursive, however, so this property deep within event.eventResults[].results
      // never gets turned back into IDs, the same way we transform event.attendees,
      // conversation.users, etc.
      // For now, we're going to just manually map the array to it's IDs.
      // Note: Unlike the next else if statement, we don't bother comparing the old data with
      // the new, as this event result section type should be readonly today.
      const updatedEventResults = [...item[key]];
      // This overrides the entire first item, rather than just overriding the `results` property
      // because this is still an array of mobx objects, and mobx has strong feelings about how
      // this data gets touched
      updatedEventResults[0] = {
        type: updatedEventResults[0].type,
        version: updatedEventResults[0].version,
        results: {
          ...updatedEventResults[0].results,
          formDocuments: updatedEventResults[0].results.formDocuments.map(
            formDocument => formDocument._id,
          ),
        },
      };
      ret[key] = updatedEventResults;
    } else if (isArray(item[key])) {
      const oldIdsSorted =
        originalItem && originalItem[key]
          ? originalItem[key]
              .filter(obj => obj !== null)
              .map(obj => obj.id || obj)
              .sort()
          : [];
      const newIds = item[key].filter(obj => obj !== null).map(obj => obj.id || obj);
      const newIdsSorted = newIds.slice(0).sort();

      if (!isEqual(oldIdsSorted, newIdsSorted)) {
        ret[key] = newIds;
      }
    } else if (isPlainObject(item[key]) || (originalItem && isPlainObject(originalItem[key]))) {
      // The value could be null, so we need to look at both the item and the original item to
      // see if there's an object there.
      if (item[key] && item[key].id) {
        // Case 1: It's a reference. See if the reference has changed.
        const oldId = originalItem && originalItem[key] && originalItem[key].id;
        const newId = item[key].id;
        if (oldId !== newId) {
          ret[key] = newId;
        }
      } else if (item[key]) {
        // Case 2: It's set but not a reference (like a recurrence). See if the data has changed.
        const oldData = originalItem && originalItem[key];
        const newData = item[key];
        if (!isEqual(oldData, newData)) {
          ret[key] = newData;
        }
      } else if (originalItem && originalItem[key]) {
        // Case 3: It was set and now it's not, so set it to null.
        ret[key] = null;
      }
    } else {
      // Assume everything else is a scalar and should be written as is.
      ret[key] = item[key];
    }
  });
  return ret;
}
