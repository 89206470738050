import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { useRouterStore } from 'mobx-state-router';
import React, { useCallback, useContext, useState } from 'react';

import { generateRouteUrl } from 'src/boot/routes';
import { Button } from 'src/common/ui/components';
import ErrorAlert from 'src/components/general/ErrorAlert';
import { SideVisitContext } from 'src/components/pages/eventShow/SideVisitContext';
import { ApolloClientContext } from 'src/data/ApolloClientContext';
import { ACCEPT_SEE_PATIENT_MUTATION } from 'src/dropInClinic/queries';
import { AcceptMutationArgs, AcceptSeePatientMutationResponse } from 'src/dropInClinic/types';

type AcceptSeePatientSuggestionProps = {
  requestId?: string | null;
  disabled: boolean;
};

const AcceptSeePatientSuggestionButton = (props: AcceptSeePatientSuggestionProps) => {
  const { apolloClient } = useContext(ApolloClientContext);
  const { setSideVisit } = useContext(SideVisitContext);
  const [requestUpdateError, setRequestUpdateError] = useState(null);
  const router = useRouterStore();

  const handleAccept = useCallback(
    async (id: string) => {
      setRequestUpdateError(null);
      if (!apolloClient) {
        return;
      }

      try {
        const response = await apolloClient.mutate<
          AcceptSeePatientMutationResponse,
          AcceptMutationArgs
        >({
          mutation: ACCEPT_SEE_PATIENT_MUTATION,
          variables: { requestId: id },
        });

        const eventId = response.data?.acceptSeePatientDropInClinicRequest.eventId;
        const patientId = response.data?.acceptSeePatientDropInClinicRequest.patientId;
        if (eventId && patientId) {
          // Open side-by-side visit note in new tab, becomes new focused tab
          setSideVisit(patientId, eventId);
          const visitNoteUrl = generateRouteUrl('showPatient', { id: patientId });
          window.open(visitNoteUrl, '_blank');
          // Open video call in current tab
          router.goTo('eventVc', { params: { event: eventId } });
        }
      } catch (e) {
        setRequestUpdateError(e);
      }
    },
    [apolloClient, setRequestUpdateError, router, setSideVisit],
  );

  return (
    <>
      {requestUpdateError && (
        <ErrorAlert message="Error updating request" error={requestUpdateError} />
      )}
      <Button
        disabled={props.disabled || !props.requestId}
        onClick={() => props.requestId && handleAccept(props.requestId)}
        startIcon={<PersonAddIcon />}
        data-testid="accept-see-patient-suggestion-button"
      >
        Accept patient now
      </Button>
    </>
  );
};

export default AcceptSeePatientSuggestionButton;
