import escapeRegExp from 'lodash/escapeRegExp';

import { InsertedTag } from 'src/nightingale/components/ChartProperty/controls/TaggedText/TaggedTextControl.InsertedTag';
import { SuggestionList } from 'src/nightingale/components/ChartProperty/controls/TaggedText/TaggedTextControl.SuggestionList';
import { SuggestionListItem } from 'src/nightingale/components/ChartProperty/controls/TaggedText/TaggedTextControl.SuggestionListItem';

export const createHashTagAutocomplete = (tags: string[]) => ({
  component: InsertedTag,
  format: item => `${item.option}`,
  itemComponent: SuggestionListItem,
  listComponent: SuggestionList,
  mutability: 'IMMUTABLE',
  onMatch: (text: string) => {
    let choices = tags;

    if (text) {
      const escaped = escapeRegExp(text); // https://lodash.com/docs/4.17.15#escapeRegExp https://bouldercare.atlassian.net/browse/FLR-509
      const tester = new RegExp(`\\b${escaped}`, 'i');
      choices = choices.filter(choice => tester.test(choice));
    }

    return choices.map(option => ({
      option,
      matched: text,
    }));
  },
  prefix: '#',
  type: 'TAG',
});

export const findHighlightChunks = ({
  searchWords,
  textToHighlight,
}: {
  searchWords: string[];
  textToHighlight: string;
}) => {
  // we know we are only searching for one word/phrase - the thing the user is typing
  const searchWord = searchWords[0].toLowerCase();
  if (!searchWord) return [];

  const escaped = escapeRegExp(searchWord); // https://lodash.com/docs/4.17.15#escapeRegExp https://bouldercare.atlassian.net/browse/FLR-509
  const tester = new RegExp(`\\b${escaped}`, 'gi');

  const chunks: number[] = [];
  let result: RegExpExecArray | null;
  // eslint-disable-next-line no-cond-assign
  while ((result = tester.exec(textToHighlight))) {
    chunks.push(result.index);
  }

  return chunks.map(index => ({
    highlight: true,
    start: index,
    end: index + searchWord.length,
  }));
};
