import React, { useContext, useEffect, useRef } from 'react';

import { PatientShowContext } from 'src/components/pages/patientShow/PatientShowContext';
import { PageTitle } from 'src/nightingale/components/common/PageTitle/PageTitle';

const TOP_NAV_HEIGHT = 64;

export const ChartOverview: React.FC = props => {
  const { children } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const { pageContentsRef, urlFragment } = useContext(PatientShowContext);

  // Check if we have a chart element referenced by the URL fragment and scroll to it if so
  useEffect(() => {
    const elementToScroll = pageContentsRef?.current;
    if (!elementToScroll) return;

    if (!urlFragment) return;

    const selector = `[data-chart-item-type="ChartElement"][data-chart-item-name="${urlFragment}"]`;
    const elementToScrollTo = containerRef.current?.querySelector(selector);
    if (!elementToScrollTo) return;

    // NOTE: A bounding client rect `top` is relative to the top of the *viewport*.
    // It'll work to just scroll directly to this when the page first loads, but if we have for example
    // scrolled *below* the element, this `top` will be a negative number. And if we hand a negative number
    // to the `scrollTo` later, it'll just go to the top of the page, since it treats that input as relative
    // to its own top.
    const { top } = elementToScrollTo.getBoundingClientRect();
    const scrollTop =
      elementToScroll.scrollTop + // The distance we have already scrolled our page content
      top - // The top of the target, relative to the viewport
      TOP_NAV_HEIGHT - // Offset for the navbar
      16; // A little distance from the navbar

    elementToScroll.scrollTo({ top: scrollTop, behavior: 'smooth' });
  }, [urlFragment, pageContentsRef]);

  return (
    <div ref={containerRef}>
      <PageTitle>Overview</PageTitle>
      {children}
    </div>
  );
};
