import gql from 'graphql-tag';

export const VISIT_CODE_USED = gql(`
  subscription OnVisitCodeUsed($code: String!) {
    staff_onVisitCodeUsed(code: $code) {
      code
    }
  }
`);

export const SEND_VISIT_CODE_EVENT = gql(`
  mutation SendVisitCodeEvent($code: String!, $eventId: ID!) {
    staff_sendVisitCodeEvent(code: $code, eventId: $eventId)
  }
`);
