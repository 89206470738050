import { makeStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { format, isBefore, isValid, parseISO } from 'date-fns';
import gql from 'graphql-tag';
import React, { useContext, useEffect, useMemo } from 'react';
import useSWR from 'swr';

import ErrorAlert from 'src/components/general/ErrorAlert';
import Colors from 'src/nightingale/Colors';
import { FlowMappingsContextProvider } from 'src/nightingale/components/Flow/FlowMappingsContext';
import LazyLoadingSpinner from 'src/nightingale/components/common/LazyLoadingSpinner/LazyLoadingSpinner';
import { InteractionKind } from 'src/nightingale/types/types';
import { PebblesUpdateContext } from 'src/pebbles/PebblesUpdateContext';
import Pebble from 'src/pebbles/components/Pebble';
import {
  PEBBLE_STATUS_MAP,
  PEBBLE_TOPIC_MAP,
  PebbleStatus,
  PebblePriority,
} from 'src/util/pebbles';

const LOAD_PATIENT_PEBBLES = gql`
  query PatientPebbles($where: PebbleWhereInput) {
    pebbles(where: $where) {
      assignee {
        firstName
        lastName
      }
      createdAt
      id
      priority
      reminder
      status
      title
      topic
      updatedAt
      updatedBy {
        firstName
        lastName
      }
    }
  }
`;

type PatientPebblesProps = {
  patientId: string;
};

type MinimalGraphQlPebble = {
  assignee: { firstName: string; lastName: string } | null;
  id: string;
  priority: PebblePriority;
  reminder: string | null;
  status: PebbleStatus;
  title: string | null;
  topic: string;
  updatedAt: string | null;
  updatedBy: { firstName: string; lastName: string } | null;
};

export type PebbleRow = Omit<MinimalGraphQlPebble, 'updatedAt' | 'updatedBy'> & {
  lastUpdated: {
    updatedAt: Date | null;
    updatedBy: { firstName: string; lastName: string } | null;
  };
};

type PebbleRowSortProps = Pick<
  PebbleRow,
  'topic' | 'title' | 'assignee' | 'status' | 'lastUpdated'
>;

const PatientPebblesTable: React.VFC<PatientPebblesProps> = ({ patientId }) => {
  const styles = useStyles();
  const { lastUpdate } = useContext(PebblesUpdateContext);

  const {
    data: { pebbles } = {},
    error,
    mutate,
  } = useSWR([LOAD_PATIENT_PEBBLES, { where: { participant_some: patientId } }]);
  useEffect(() => {
    if (lastUpdate) {
      mutate();
    }
  }, [lastUpdate, mutate]);

  const isLoading = !pebbles;

  const [shouldShowOpen, setShouldShowOpen] = React.useState(false);
  const handlePebbleStatusToggle = (showOpen: boolean) => {
    setShouldShowOpen(showOpen);
  };

  const filteredPebbles = useMemo<PebbleRow[]>(() => {
    const pebbleRecords = (pebbles || []).map(pebble => ({
      ...pebble,
      lastUpdated: {
        updatedAt: new Date(pebble.updatedAt),
        updatedBy: pebble.updatedBy,
      },
    }));
    return filterPebbles(pebbleRecords, shouldShowOpen);
  }, [pebbles, shouldShowOpen]);

  const [orderBy, setOrderBy] = React.useState<keyof PebbleRowSortProps>('lastUpdated');
  const [orderDir, setOrderDir] = React.useState<OrderDir>('desc');

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof PebbleRowSortProps,
  ) => {
    const isAsc = orderBy === property && orderDir === 'asc';
    setOrderDir(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      {error ? <ErrorAlert message="Error loading patient pebbles." error={error} /> : null}
      <FormControlLabel
        control={
          <Checkbox
            data-testid="patient-pebbles-show-open"
            checked={shouldShowOpen}
            onChange={event => handlePebbleStatusToggle(event.target.checked)}
          />
        }
        label="Show open only"
      />
      <Paper>
        <FlowMappingsContextProvider kind={InteractionKind.Pebble}>
          <TableContainer>
            <Table className={styles.pebblesTable}>
              <SortablePatientPebblesTableHead
                onRequestSort={handleRequestSort}
                orderBy={orderBy}
                orderDir={orderDir}
              />
              <TableBody className={styles.tableBody}>
                {filteredPebbles.length ? (
                  stableSort(filteredPebbles, orderBy, orderDir).map(pebble => (
                    <PatientPebblesTableRow
                      key={pebble.id}
                      onSave={() => {
                        mutate();
                      }}
                      pebble={pebble}
                      styles={styles}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell className={styles.emptyMessage} colSpan={6}>
                      {isLoading ? <LazyLoadingSpinner /> : <>No records to display</>}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </FlowMappingsContextProvider>
      </Paper>
    </>
  );
};

function stableSort(
  pebbles: PebbleRow[],
  orderBy: keyof PebbleRowSortProps,
  orderDir: OrderDir,
): PebbleRow[] {
  return [...pebbles].sort((a: PebbleRow, b: PebbleRow) => {
    let comparison = getSortValue(a, orderBy).localeCompare(getSortValue(b, orderBy));
    if (comparison === 0) {
      // If equal, use pebble ID as tiebreaker to provide stable sorting
      comparison = a.id.localeCompare(b.id);
    }
    return orderDir === 'asc' ? comparison : -comparison;
  });
}

function getSortValue(pebble: PebbleRow, key: keyof PebbleRowSortProps): string {
  // If we had a lot of pebbles in the list then maybe these function calls (particularly date
  // stuff) might perform poorly, but I'm betting on it being fine for now.
  switch (key) {
    case 'assignee':
      return getAssigneeName(pebble);
    case 'status':
      return PEBBLE_STATUS_MAP[pebble.status];
    case 'topic':
      return PEBBLE_TOPIC_MAP[pebble.topic];
    case 'lastUpdated':
      return pebble.lastUpdated.updatedAt?.toISOString() ?? '';
    default:
      return pebble[key] ?? '';
  }
}

interface HeadCell {
  id: keyof PebbleRowSortProps;
  label: string;
}

const headCells: HeadCell[] = [
  { id: 'topic', label: 'Topic' },
  { id: 'title', label: 'Title' },
  { id: 'assignee', label: 'Assignee' },
  { id: 'status', label: 'Status' },
  { id: 'lastUpdated', label: 'Last Updated' },
];

type OrderDir = 'asc' | 'desc';

function SortablePatientPebblesTableHead(props: {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof PebbleRow) => void;
  orderDir: OrderDir;
  orderBy: string;
}) {
  const { orderBy, orderDir, onRequestSort } = props;
  const createSortHandler = (property: keyof PebbleRow) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell /> {/* extra empty cell for the expander arrow column */}
        {headCells.map(headCell => (
          <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? orderDir : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? orderDir : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function PatientPebblesTableRow({
  onSave,
  pebble,
  styles,
}: {
  onSave: () => void;
  pebble: PebbleRow;
  styles: ReturnType<typeof useStyles>;
}) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow className={isRowHighlighted(pebble) ? styles.highlightedRow : undefined}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{PEBBLE_TOPIC_MAP[pebble.topic] ?? pebble.topic}</TableCell>
        <TableCell>{pebble.title}</TableCell>
        <TableCell>{getAssigneeName(pebble)}</TableCell>
        <TableCell>{PEBBLE_STATUS_MAP[pebble.status] ?? pebble.status}</TableCell>
        <TableCell>
          <div>
            {pebble.lastUpdated.updatedAt ? format(pebble.lastUpdated.updatedAt, 'MMM d p') : ''}
          </div>
          <div className={styles.lastUpdatedBy}>
            by {pebble.lastUpdated.updatedBy?.firstName} {pebble.lastUpdated.updatedBy?.lastName}
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Pebble id={pebble.id} onSave={onSave} canEditInteraction />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function isPastDue(rowData: PebbleRow): boolean {
  const reminder = rowData.reminder ? parseISO(rowData.reminder) : rowData.reminder;
  if (!reminder || !isValid(reminder)) {
    return false;
  }

  return (
    isBefore(new Date(reminder), new Date()) &&
    ![PebbleStatus.WontDo, PebbleStatus.Completed].includes(rowData.status)
  );
}

function isRowHighlighted(rowData: PebbleRow): boolean {
  return isPastDue(rowData) || rowData.priority === PebblePriority.Urgent;
}

const useStyles = makeStyles({
  lastUpdatedBy: {
    color: Colors.Gray5,
    fontSize: '90%',
    fontStyle: 'italic',
  },
  pebblesTable: {
    '& tr': {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
  },
  tableBody: {
    '& td': {
      padding: '5px 10px',
      fontSize: '14px',
    },
  },
  highlightedRow: {
    '& td': {
      color: Colors.Coral,
    },
  },
  emptyMessage: {
    textAlign: 'center',
  },
});

const getAssigneeName = (pebble: MinimalGraphQlPebble | PebbleRow) =>
  `${pebble.assignee?.firstName} ${pebble.assignee?.lastName}`;

/**
 * Apply any necessary filtering logic to the pebbles before displaying them (e.g., by status)
 */
function filterPebbles(pebbles: PebbleRow[], openOnly: boolean): PebbleRow[] {
  return openOnly
    ? pebbles.filter(pebble => pebble.status !== 'completed' && pebble.status !== 'wont_do')
    : pebbles;
}

export const __test__ = { LOAD_PATIENT_PEBBLES, isRowHighlighted };

export default PatientPebblesTable;
