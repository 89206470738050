"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.eventdatediffcalendar = eventdatediffcalendar;
var _dateFns = require("date-fns");
var _dateFnsTz = require("date-fns-tz");
var _dateCompatabilityShim = require("./dateCompatabilityShim");
const LIMITED_TIME_EVENT_TYPES = ['appointment_virtual', 'appointment_inperson', 'availability'];
function eventdatediffcalendar(from, to, timezone, eventType) {
  const fromDate = (0, _dateCompatabilityShim.parseUnknownDate)(from);
  const toDate = (0, _dateCompatabilityShim.parseUnknownDate)(to);
  if (!(0, _dateFns.isValid)(fromDate) || !(0, _dateFns.isValid)(toDate)) {
    return undefined;
  }
  const isAllDayEvent = eventType && !LIMITED_TIME_EVENT_TYPES.includes(eventType);
  const zonedFrom = isAllDayEvent ? (0, _dateFnsTz.utcToZonedTime)(getAdjustedUtcTime(fromDate, timezone), timezone) : (0, _dateFnsTz.utcToZonedTime)(fromDate, timezone);
  const zonedTo = (0, _dateFnsTz.utcToZonedTime)(toDate, timezone);
  return Math.abs((0, _dateFns.differenceInCalendarDays)(zonedFrom, zonedTo));
}
function getAdjustedUtcTime(utcTime, timezone) {
  const tzOffset = (0, _dateFnsTz.getTimezoneOffset)(timezone, utcTime);
  return tzOffset < 0 ? (0, _dateFns.subMilliseconds)(utcTime, tzOffset) : (0, _dateFns.addMilliseconds)(utcTime, tzOffset);
}