import classNames from 'classnames';
import React from 'react';

import { Copy } from 'src/common/ui/components';
import { useStyles as usePreVisitDisplayStyles } from 'src/events/styles/preVisitDisplay.styles';

type VisitReasonDetails = {
  currentPharmacy?: string;
  pharmacyUpdated: string;
};

type PharmacyChangeRequestProps = {
  visitReasonDetails: VisitReasonDetails;
};

export const PharmacyChangeRequest = ({ visitReasonDetails }: PharmacyChangeRequestProps) => {
  const { currentPharmacy, pharmacyUpdated } = visitReasonDetails || {};

  const preVisitDisplayStyles = usePreVisitDisplayStyles();

  if (!currentPharmacy) {
    return null;
  }

  if (pharmacyUpdated) {
    return null;
  }

  return (
    <div
      className={classNames([
        preVisitDisplayStyles.sectionContainer,
        preVisitDisplayStyles.highlight,
      ])}
    >
      <div className={preVisitDisplayStyles.sectionLabel}>Pharmacy Change Request</div>
      <Copy variant="body2">{`"${visitReasonDetails.currentPharmacy}"`}</Copy>
    </div>
  );
};
