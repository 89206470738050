import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import React, { useCallback, useContext, useState } from 'react';

import { Button, Select, TextField } from 'src/common/ui/components';
import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import ErrorAlert from 'src/components/general/ErrorAlert';
import TitleBar from 'src/components/pages/pageElements/titleBar';
import { UPDATE_PHARMACY_RECOMMENDATIONS } from 'src/featureFlags/currentFlags';
import { PharmacyItem } from 'src/pharmacyRecommendations/components/PharmacyItem';
import { usePharmacyRecommendations } from 'src/pharmacyRecommendations/usePharmacyRecommendations';
import { STATES, STATES_BY_ABBREVIATION } from 'src/util/usStates';

const PharmacyRecommendations: React.VFC = () => {
  const classes = useStyles();

  const [cityInput, setCityInput] = useState<string | null>(null);
  const [nameInput, setNameInput] = useState<string | null>(null);
  const [stateInput, setStateInput] = useState<string | null>(null);
  const [zipInput, setZipInput] = useState<string | null>(null);
  const [searchKey, setSearchKey] = useState<{
    city: string | null;
    name: string | null;
    state: string | null;
    zip: string | null;
  }>({
    city: null,
    name: null,
    state: null,
    zip: null,
  });
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const { pharmacies, error, mutate } = usePharmacyRecommendations(
    searchKey.city,
    searchKey.name,
    searchKey.state,
    searchKey.zip,
  );

  const flags = useContext(FeatureFlagContext);
  const allowPharmacyUpdates = flags[UPDATE_PHARMACY_RECOMMENDATIONS];

  /**
   * Helper that allows us to update the pharmacies list in the swr cache without re-fetching
   * whenever a pharmacy recommendation is successfully updated.
   */
  const updatePharmacyList = useCallback(
    updatedPharmacy => {
      mutate(
        {
          staff_pharmacies: pharmacies.map(pharmacy =>
            pharmacy.id === updatedPharmacy.id ? updatedPharmacy : pharmacy,
          ),
        },
        false,
      );
    },
    [pharmacies, mutate],
  );

  if (error) {
    return <ErrorAlert message="Error fetching pharmacy recommendations." error={error} />;
  }

  const setSearch = () => {
    setSearchKey({ city: cityInput, name: nameInput, state: stateInput, zip: zipInput });
  };

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <TitleBar title="Pharmacy Recommendations" />
      </div>

      <div className={classes.searchInput}>
        <TextField label="Pharmacy Name" onChange={e => setNameInput(e.target.value)} />
        <TextField label="City" onChange={e => setCityInput(e.target.value)} />
        <Select
          options={Object.values(STATES).map(state => state.abbreviation)}
          getOptionLabel={option => STATES_BY_ABBREVIATION[option]}
          onChange={(_, val: string | null) => setStateInput(val)}
          label="State"
        />
        <TextField label="Zip" onChange={e => setZipInput(e.target.value)} />
        <div className={classes.button}>
          <Button
            onClick={setSearch}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                setSearch();
              }
            }}
          >
            Search
          </Button>
        </div>
      </div>

      <div className={classes.table}>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Street Address</TableCell>
                  <TableCell>City</TableCell>
                  <TableCell>State</TableCell>
                  <TableCell>Zip</TableCell>
                  <TableCell>Recommendation</TableCell>
                  <TableCell>Recommendation Reasons</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pharmacies.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                  <PharmacyItem
                    key={row.id}
                    pharmacy={row}
                    updatePharmacyList={updatePharmacyList}
                    allowPharmacyUpdates={allowPharmacyUpdates}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 25]}
            component="div"
            count={pharmacies.length}
            onChangeRowsPerPage={event => setRowsPerPage(Number.parseInt(event.target.value, 10))}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={(e, newPage) => setPage(newPage)}
          />
        </Paper>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  title: {
    flex: '0 0 auto',
  },
  searchInput: {
    display: 'flex',
    flexDirection: 'column',
    width: 400,
    gap: theme.spacing(2),
  },
  button: {
    alignSelf: 'flex-end',
  },
  table: {
    marginTop: theme.spacing(2),
  },
}));

export default PharmacyRecommendations;
