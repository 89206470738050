import { css } from '@emotion/react';

export const selectSingleCss = css`
  .MuiInput-root .MuiInput-input {
    width: 100%;
  }
`;

export const selectMultipleCss = css`
  .MuiInput-root {
    display: flex;
    flex-direction: row;

    & .MuiInput-input {
      width: auto;
      height: 32px;
      line-height: 32px;
    }
  }
`;
