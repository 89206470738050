import { Colorize, People, RecordVoiceOver } from '@material-ui/icons';
import { IconCalendarStats, IconPillOff } from '@tabler/icons-react';
import { format, isValid } from 'date-fns';
import React from 'react';

import { DrugScreeningStatusLabel } from 'src/components/general/PatientMenu/highlights/DrugScreeningPeriodStatus';
import Fyi from 'src/components/general/PatientMenu/highlights/Fyi';
import { VisitReason } from 'src/dropInClinic/visitReason/VisitReason';
import AcceptPopInClinicSuggestionButton from 'src/myDayToday/components/AcceptPopInClinicSuggestionButton';
import { CardDivider } from 'src/myDayToday/components/CardDivider';
import { EventTypeLabel } from 'src/myDayToday/components/EventSubType';
import { EventSubTypeIcon } from 'src/myDayToday/components/EventSubTypeIcon';
import { InformationRow } from 'src/myDayToday/components/InformationRow';
import { MedicationRunOutLabel } from 'src/myDayToday/components/MedicationRunOutLabel';
import { PatientName } from 'src/myDayToday/components/PatientName';
import { PopInVisitCardHeader } from 'src/myDayToday/components/PopInVisitCardHeader';
import { useScheduledEventCardStyles } from 'src/myDayToday/components/ScheduledEventCard.styles';
import { StateBadge } from 'src/myDayToday/components/StateBadge';
import { VisitTimeFrame } from 'src/myDayToday/components/VisitTimeFrame';
import { getScheduledEventTiming } from 'src/myDayToday/components/getScheduledEventTiming';
import { EventCardPatient } from 'src/myDayToday/types/EventCardPatient';

type PopInVisitCardProps = {
  patient: EventCardPatient;
  requestId: string;
  visitReason?: string;
  visitType: string;
  currentTime: Date;
  scheduledStartTime: Date;
  expectedDuration: number;
  reasonDetails?: Record<string, any>;
  hasBeenSufficientlyNotified: boolean;
};

const PopInVisitCard = (props: PopInVisitCardProps) => {
  const classes = useScheduledEventCardStyles();

  const eventTiming = getScheduledEventTiming(props.currentTime, props.scheduledStartTime);

  const containerClasses = [classes.container, eventTiming];

  let reason: React.ReactNode | null = null;
  if (props.visitReason) {
    reason = props.visitReason;
  } else if (props.reasonDetails) {
    reason = <VisitReason visitReasonDetails={props.reasonDetails} />;
  }

  return (
    <div className={containerClasses.join(' ')} data-testid="pop-in-clinic-card">
      <div className="header">
        <PopInVisitCardHeader
          currentTime={props.currentTime}
          start={props.scheduledStartTime}
          timing={eventTiming}
        />
      </div>
      <div className="body">
        <div className="avatar">
          <img src={props.patient.avatarUrl} />
          <div className="patientInformation">
            <StateBadge city={props.patient.city} state={props.patient.state} />
            <Fyi patientId={props.patient.patientId} />
          </div>
        </div>
        <div className="details">
          <div className="visitType">
            <EventSubTypeIcon subType={props.visitType} size={18} />
            <EventTypeLabel subType={props.visitType} />
          </div>
          <div className="patientName">
            <PatientName {...props.patient} />
          </div>
          <VisitTimeFrame start={props.scheduledStartTime} duration={props.expectedDuration} />

          <CardDivider />

          <div className="informationList">
            <InformationRow icon={<RecordVoiceOver />} label="Visit reason" value={reason} />
            <InformationRow
              icon={<IconCalendarStats />}
              label="Visit cadence"
              value={props.patient.visitCadence}
            />
            <InformationRow
              icon={<IconPillOff />}
              label="Bupe runout date"
              value={<MedicationRunOutLabel runOutDate={props.patient.medicationRunOutDate} />}
            />
            <InformationRow
              icon={<Colorize />}
              label="Drug screening status"
              value={
                <DrugScreeningStatusLabel
                  screeningStatus={props.patient.drugScreeningStatus}
                  defaultLabel="--"
                />
              }
            />
            <InformationRow
              icon={<People />}
              label="Last visit"
              value={
                // Note for 'as Date': isValid allows us to be sure this is a Date
                isValid(props.patient.lastVisit)
                  ? format(props.patient.lastVisit as Date, 'M/d/yyyy')
                  : null
              }
            />
          </div>

          <CardDivider />

          <AcceptPopInClinicSuggestionButton
            requestId={props.requestId}
            disabled={!props.hasBeenSufficientlyNotified}
          />
        </div>
      </div>
    </div>
  );
};

export default PopInVisitCard;
