import { gql } from 'src/generated/gql';

export const GET_EVENT_CARDS_QUERY = gql(`
  query staff_getEventCards($providerId: ID!) {
    staff_myDayTodayEventCards(providerId: $providerId) {
      nextEvent {
        id
        requestId
        classification
        title
        start
        duration
        type
        subType
        visitReason
        reasonDetails
        patient {
          id
          firstName
          lastName
          preferredName
          profileUrl
          dspStatus
          lastVisit
          city
          state
          carePlan
          fyi
          medicationRunOutDate
        }
        prepInProgress
        hasBeenSufficientlyNotified
      }
      emptyCardClassification
      alternativeDropInCards {
        id
        requestId
        classification
        title
        start
        duration
        type
        subType
        visitReason
        reasonDetails
        patient {
          id
          firstName
          lastName
          preferredName
          profileUrl
          dspStatus
          lastVisit
          city
          state
          carePlan
          fyi
          medicationRunOutDate
        }
        prepInProgress
        hasBeenSufficientlyNotified
      }
    }
    staff_getProviderUnsignedVisits(providerId: $providerId) {
      id
      attendeesDisplay {
        firstName
        lastName
        preferredName
        userType
      }
    }
  }
`);
