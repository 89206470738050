import IconButton from '@material-ui/core/IconButton';
import { Theme, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { ResizableBox } from 'react-resizable';

import VisitNotes from 'src/components/pages/pageElements/visitNotes';
import Colors from 'src/nightingale/Colors';
import { SideBySideSnapshotSnackbar } from 'src/nightingale/components/SnapshotSnackbar/SideBySideSnapshotSnackbar';
import { PageContainer } from 'src/nightingale/components/common/PageContainer/PageContainer';
import { theme as appTheme } from 'src/theme';

const TOP_NAV_HEIGHT = 64;

const SideVisit: FunctionComponent<{
  onClose?: () => void;
}> = ({ onClose }) => {
  const scrollRef = useRef<HTMLElement>(null);
  const { top } = useScroll(scrollRef);
  const hasScroll = useMemo(() => top > 0, [top]);
  const classes = useStyles({ hasScroll });
  const windowHeight = useWindowHeight();
  /** Chart elements are 600px, plus padding on the left, then container padding */
  const width = 600 + appTheme.spacing(3) * 3;

  return (
    <ResizableBox
      axis="x"
      className={classes.container}
      height={windowHeight - TOP_NAV_HEIGHT}
      resizeHandles={['w']}
      width={width}
    >
      <aside className={classes.content} ref={scrollRef}>
        <menu className={classes.header}>
          <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </menu>
        <PageContainer component="section">
          <VisitNotes snackbarComponent={SideBySideSnapshotSnackbar} />
        </PageContainer>
      </aside>
    </ResizableBox>
  );
};

const useStyles = makeStyles<Theme, { hasScroll: boolean }>(theme => ({
  container: {
    borderLeft: '2px solid white',
    position: 'relative',
    '& .react-resizable-handle': {
      boxSizing: 'border-box',
      cursor: 'ew-resize',
      height: '100%',
      left: -theme.spacing(1.5),
      position: 'absolute',
      top: '0',
      width: theme.spacing(3),
    },
  },
  header: {
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 0,
    marginBottom: -theme.spacing(3),
    padding: 0,
    position: 'sticky',
    top: 0,
    // Making this element sticky layers it below the visit notes. But we want it to float over
    // them, so override the z-index.
    zIndex: 1000,
  },
  closeButton: {
    background: Colors.ChartGray,
    boxShadow: ({ hasScroll }) => (hasScroll ? `0 0 5px rgba(0, 0, 0, 0.25)` : 'none'),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    transition: 'box-shadow 0.3s ease-out, background 0.2s ease-out',

    '&:hover, &:active': {
      background: Colors.Gray3,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100vh - ${TOP_NAV_HEIGHT}px)`,
    overflow: 'scroll',
    '@media print': {
      height: '100%',
    },
  },
}));

/** Gets the window height through resizes, causing re-renders etc. */
function useWindowHeight() {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateHeight = debounce(() => {
      setWindowHeight(window.innerHeight);
    }, 200);

    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  return windowHeight;
}

/** Gets the given container's scroll values */
function useScroll(ref: React.MutableRefObject<HTMLElement | null>) {
  const [scroll, setScroll] = useState({
    top: ref.current?.scrollTop || 0,
    left: ref.current?.scrollLeft || 0,
  });

  useEffect(() => {
    const { current } = ref;

    const updateScroll = throttle(() => {
      if (!current) return;

      setScroll({
        top: current.scrollTop || 0,
        left: current.scrollLeft || 0,
      });
    }, 200);

    current?.addEventListener('scroll', updateScroll);
    return () => current?.removeEventListener('scroll', updateScroll);
  }, [ref]);

  return scroll;
}

export default SideVisit;
