import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useState, useCallback } from 'react';

import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import Snackbar from 'src/components/general/Snackbar';
import { SMS_COMMUNICATIONS } from 'src/featureFlags/currentFlags';
import Colors from 'src/nightingale/Colors';
import { InlineHelp } from 'src/nightingale/components/ChartElement/InlineHelp';
import logger from 'src/shared/util/logger';
import BulkPatientIdTextInput, {
  isInputValid,
} from 'src/smsCommunications/components/BulkPatientIdTextInput';
import MessageSelector from 'src/smsCommunications/components/MessageSelector';
import MessageSendButton from 'src/smsCommunications/components/MessageSendButton';
import {
  duplicatePatientIds,
  invalidPatientIds,
  validPatientIds,
} from 'src/smsCommunications/domain/patientIdListValidation';
import type { Message } from 'src/smsCommunications/hooks/useGetPatientBulkNotificationsOptions';
import { useSendBulkNotifications } from 'src/smsCommunications/hooks/useSendBulkNotifications';

const ErrorList = ({ list }) => {
  const { invalid = [], duplicate = [] } = list;
  const classes = useErrorStyles();
  return invalid.length || duplicate.length ? (
    <div className={classes.errorWrapper}>
      <span className={classes.errorTitle}>Invalid IDs</span>
      <div className={classes.errorContainer}>
        <ul className={classes.errorList}>
          {invalid.map(patientId => (
            <li className={classes.errorListItem}>
              <span className={classes.errorText}>{`(I) ${patientId}`}</span>
            </li>
          ))}
          {duplicate.map(patientId => (
            <li className={classes.errorListItem}>
              <span className={classes.errorText}>{`(D) ${patientId}`}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  ) : null;
};

const useErrorStyles = makeStyles({
  errorWrapper: {
    whiteSpace: 'nowrap',
  },
  errorContainer: {
    maxHeight: 180,
    width: 250,
    border: '1px solid',
    borderRadius: 5,
    borderColor: Colors.BlueSpruce,
    backgroundColor: Colors.Gray1,
    overflow: 'auto',
    padding: 10,
  },
  errorText: {
    fontFamily: 'Courier',
    fontSize: 14,
  },
  errorListItem: {
    listStyleType: 'none',
  },
  errorList: {
    color: 'red',
    padding: 0,
    margin: 0,
  },
  errorIcon: {
    color: 'red',
  },
  errorTitle: {
    fontFamily: '"Nunito", "Nunito Sans"',
    fontWeight: 600,
    fontSize: 10,
    lineHeight: '14.5px',
    letterSpacing: `.12em`,
    color: Colors.Gray6,
    textTransform: 'uppercase',
    transform: 'scale(1)',
    position: 'static',
  },
});

const UnauthorizedView = () => <div>Unauthorized - You do not have access to this page</div>;

const SmsCommunicationsPage: React.FC = () => {
  const classes = useStyles();
  const [message, setMessage] = useState<Message>();
  const [patientIds, setPatientIds] = useState<string[]>([]);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { sendBulkNotifications } = useSendBulkNotifications();

  const handleSendMessages = useCallback(async () => {
    if (!message) {
      return;
    }
    setIsLoading(true);
    try {
      await sendBulkNotifications({
        patientIds,
        message: message.value,
        messageKey: message.key,
      });
      setPatientIds([]);
      setMessage(undefined);
      setSuccess(true);
    } catch (err) {
      console.log('Error sending texts:', err);
      setError(true);
      logger.error(`Error sending SMS messages to patients: ${err}`);
    } finally {
      setIsLoading(false);
    }
  }, [sendBulkNotifications, patientIds, message]);

  const hasAccessToSmsCommunications = useContext(FeatureFlagContext)[SMS_COMMUNICATIONS];
  if (!hasAccessToSmsCommunications) {
    return <UnauthorizedView />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div>
          {error && (
            <Snackbar
              message="Error sending SMS"
              onDismiss={() => {
                setError(false);
              }}
              color="error"
            />
          )}
          {success && (
            <Snackbar
              message="SMS Sent Successfully"
              onDismiss={() => {
                setSuccess(false);
              }}
            />
          )}
          <section className={classes.section}>
            <span className={classes.titleText}>SMS Communications</span>

            <Card className={classes.cardContainer}>
              <section className={classes.section}>
                <CardHeader
                  title="Send a message to multiple patients"
                  classes={{ title: classes.header, root: classes.cardHeaderContainer }}
                />
              </section>

              <CardContent className={classes.cardContentContainer}>
                <section className={classes.section}>
                  <MessageSelector message={message} setMessage={setMessage} />
                </section>

                <section className={classes.patientIdsSection}>
                  <div style={{ marginRight: 10 }}>
                    <span className={classes.labelText}>Patient IDs</span>
                    <BulkPatientIdTextInput patientIds={patientIds} setPatientIds={setPatientIds} />
                    <InlineHelp helpText="Each ID should be separated by a linebreak" />
                    {validPatientIds(patientIds).length > 0 && (
                      <Typography
                        className={isInputValid(patientIds) ? classes.inputTotalValid : undefined}
                      >
                        {validPatientIds(patientIds).length} valid patient ID(s)
                      </Typography>
                    )}
                  </div>
                  <div>
                    <ErrorList
                      list={{
                        invalid: invalidPatientIds(patientIds),
                        duplicate: duplicatePatientIds(patientIds),
                      }}
                    />
                  </div>
                </section>
                <div className={classes.spacer} />

                <MessageSendButton
                  handleSendMessages={handleSendMessages}
                  isDisabled={isLoading || !isInputValid(patientIds) || !message}
                  message={message?.value}
                  numPatients={patientIds.length}
                />
                <InlineHelp helpText="The message will be sent to patients in batches of 100 every 30 seconds." />
              </CardContent>
            </Card>
          </section>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  inputWrapper: { display: 'flex', flex: 1 },
  inputFeedbackSection: { flex: 1 },
  inputTotalValid: { color: 'green' },
  spacer: {
    flex: '1 1 100%',
  },
  content: {
    maxWidth: 600,
  },
  header: {
    fontFamily: '"Nunito", "Nunito Sans"',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '17.4px',
    '&, &.Mui-error, &.Mui-focused': {
      color: Colors.BlueSpruce,
    },
    transform: 'scale(1)',
    position: 'static',
  },
  cardContainer: {
    backgroundColor: Colors.White,
    borderRadius: 0,
    boxShadow: 'none',
    marginTop: 30,
    overflow: 'visible',
    padding: 16,
  },
  cardHeaderContainer: {
    padding: 0,
  },
  cardContentContainer: {
    padding: 0,
    position: 'relative',
    '&:last-child': { paddingBottom: 0 },
  },
  patientIdsSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  title: {
    flex: '0 0 auto',
    marginBottom: 16,
  },
  section: {
    marginBottom: 20,
  },
  labelText: {
    fontFamily: '"Nunito", "Nunito Sans"',
    fontWeight: 600,
    fontSize: 10,
    lineHeight: '14.5px',
    letterSpacing: `.12em`,
    color: Colors.Gray6,
    textTransform: 'uppercase',
    transform: 'scale(1)',
    position: 'static',
  },
  titleText: {
    fontFamily: 'Tenor Sans',
    fontSize: 26,
    lineHeight: '145%',
    color: Colors.Gray6,
    textTransform: 'capitalize',
    height: '50px',
    marginBottom: '30px',
  },
  root: {
    backgroundColor: Colors.ChartGray,
    padding: theme.spacing(3),
    height: '100%',
    minHeight: '100vh',
  },
}));

export default SmsCommunicationsPage;
