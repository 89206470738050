import gql from 'graphql-tag';
import { useContext, useCallback } from 'react';

import { ApolloClientContext } from 'src/data/ApolloClientContext';

export const SEND_BULK_PATIENT_NOTIFICATIONS = gql`
  mutation ($patientIds: [String!]!, $message: String!, $messageKey: String!) {
    sendBulkPatientNotifications(
      patientIds: $patientIds
      message: $message
      messageKey: $messageKey
    )
  }
`;

export const useSendBulkNotifications = () => {
  const { apolloClient } = useContext(ApolloClientContext);

  const sendBulkNotifications = useCallback(
    async ({
      patientIds,
      message,
      messageKey,
    }: {
      patientIds: string[];
      message: string;
      messageKey: string;
    }) => {
      if (!apolloClient || !message || !patientIds?.length) return;
      await apolloClient.mutate({
        mutation: SEND_BULK_PATIENT_NOTIFICATIONS,
        variables: {
          patientIds,
          message,
          messageKey,
        },
      });
    },
    [apolloClient],
  );

  return { sendBulkNotifications };
};
