import { gql } from 'src/generated/gql';

export const PHARMACIES_QUERY = gql(`
  query Pharmacies($city: String, $name: String, $state: String, $zip: String) {
    staff_pharmacies(city: $city, name: $name, state: $state, zip: $zip) {
      id
      ncpdpId
      doseSpotIds
      name
      street1
      street2
      city
      state
      zip
      phone
      fax
      recommendation
      recommendationReasons
    }
  }
`);
