/** @jsxImportSource @emotion/react */
import MuiTextField, { type TextFieldProps } from '@mui/material/TextField';
import React from 'react';

/**
 * Wrapper around MUI's TextField component with custom styles.
 *
 * @see {@link https://mui.com/material-ui/react-text-field/}
 */
export const TextField = ({
  autoFocus,
  defaultValue,
  disabled,
  error,
  helperText,
  id,
  label,
  multiline,
  name,
  onChange,
  placeholder,
  required,
  rows,
  type,
  value,
}: Pick<
  TextFieldProps,
  | 'autoFocus'
  | 'defaultValue'
  | 'disabled'
  | 'error'
  | 'helperText'
  | 'id'
  | 'label'
  | 'multiline'
  | 'name'
  | 'onChange'
  | 'placeholder'
  | 'required'
  | 'rows'
  | 'type'
  | 'value'
>) => (
  <MuiTextField
    autoFocus={autoFocus}
    data-testid="nds-component-textfield"
    defaultValue={defaultValue}
    disabled={disabled}
    error={error}
    helperText={helperText}
    id={id}
    label={label}
    multiline={multiline}
    name={name}
    onChange={onChange}
    placeholder={placeholder}
    required={required}
    rows={rows}
    select={false} // Use the Select component instead
    slotProps={{
      inputLabel: {
        shrink: true,
      },
    }}
    type={type}
    value={value}
    variant="standard"
  />
);
