import { makeStyles } from '@material-ui/core';
import keyBy from 'lodash/keyBy';
import React from 'react';

import Colors from 'src/nightingale/Colors';
import { SelectControl } from 'src/nightingale/components/ChartProperty/controls/Select/SelectControl';
import { SelectOption } from 'src/nightingale/types/types';
import {
  type Message,
  useGetPatientBulkNotificationsOptions,
} from 'src/smsCommunications/hooks/useGetPatientBulkNotificationsOptions';

const MessageSelector = ({
  message,
  setMessage,
}: {
  message: Message | undefined;
  setMessage: (Message) => void;
}) => {
  const classes = useStyles();
  const messages = useGetPatientBulkNotificationsOptions();
  const messagesByValue = keyBy(messages, 'value');

  return (
    <div>
      <SelectControl
        options={messages}
        onChangeValue={(option: SelectOption) => {
          if (option?.value) {
            setMessage(messagesByValue[option.value]);
          } else if (message !== undefined) {
            setMessage(undefined);
          }
        }}
        allowMultiple={false}
        value={message && messagesByValue[message.value]}
        label="Select a message to send to patients via sms"
      />
      {message && (
        <div className={classes.messagePreviewContainer}>
          <span className={classes.messagePreviewLabel}>Message Preview</span>
          <span className={classes.messagePreviewBody}>{message.value}</span>
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  messagePreviewContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    '& span': {
      display: 'block',
    },
  },
  messagePreviewLabel: {
    fontFamily: '"Nunito", "Nunito Sans"',
    fontWeight: 600,
    fontSize: 10,
    lineHeight: '14.5px',
    letterSpacing: `.12em`,
    color: Colors.Gray6,
    textTransform: 'uppercase',
    transform: 'scale(1)',
    position: 'static',
  },
  messagePreviewBody: {
    fontFamily: '"Nunito", "Nunito Sans"',
    fontSize: 16,
    color: Colors.Gray6,
    fontStyle: 'italic',
  },
});

export default MessageSelector;
