import { makeStyles } from '@material-ui/core';
import parse from 'html-react-parser';
import escapeRegExp from 'lodash/escapeRegExp';
import React from 'react';
import Highlighter from 'react-highlight-words';

import { SearchTermContext } from 'src/nightingale/components/ChartSearch/SearchTermContext';

const useStyles = makeStyles({
  highlight: {
    color: 'inherit',
    backgroundColor: 'transparent',
    textDecoration: 'underline',
  },
});

export const Underlined: React.FC<{
  searchTerm?: string;
  text: string;
}> = ({ searchTerm, text }) => {
  if (!searchTerm) return <>{parse(text)}</>;

  const replace = (domNode: any) => {
    if (domNode.type === 'text' && domNode.data.trim() !== '') {
      return <Highlighted searchTerm={searchTerm} text={domNode.data} />;
    }
    return null;
  };

  return <>{parse(text, { replace })}</>;
};

const Highlighted: React.FC<{
  searchTerm: string;
  text: string;
}> = ({ searchTerm, text }) => {
  const styles = useStyles();
  return (
    <Highlighter
      highlightClassName={styles.highlight}
      searchWords={[escapeRegExp(searchTerm)]}
      textToHighlight={text}
    />
  );
};

export const HighlightedText: React.FC<{
  text: string;
}> = ({ text = '' }) => {
  return (
    <SearchTermContext.Consumer>
      {searchTerm => <Underlined searchTerm={searchTerm} text={text} />}
    </SearchTermContext.Consumer>
  );
};
