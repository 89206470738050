export class SynchronizedOperation<T> {
  private signal: Promise<void> = Promise.resolve();

  /**
   * Runs the given function, ensuring that all functions
   * passed to this method are run serially with each other
   */
  async run(fn: () => Promise<T>): Promise<T> {
    const { promise: newSignal, resolve: notify } = promiseWithResolver<void>();

    const prevSignal = this.signal;
    this.signal = newSignal;

    return prevSignal.then(fn).finally(notify);
  }
}

// latest JS has Promise.withResolvers, but we're not quite there yet
const promiseWithResolver = <T>() => {
  let resolve: (value: T | Promise<T>) => void;
  const promise = new Promise<T>(res => {
    resolve = res;
  });
  // the executor function is run immediately, but TS doesn't know that
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return { promise, resolve: resolve! };
};
