import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import { format, isValid, parseISO } from 'date-fns';
import { Formik } from 'formik';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import noop from 'lodash/noop';
import startCase from 'lodash/startCase';
import React, { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';

import PatientReferenceControl from 'src/components/forms/controls/PatientReference';
import DateControl from 'src/components/forms/controls/date';
import PayorReferenceControl from 'src/components/forms/controls/payorReferenceControl';
import ProviderReferenceControl from 'src/components/forms/controls/providerReference';
import ReferenceControl from 'src/components/forms/controls/reference';
import TextControl from 'src/components/forms/controls/text';
import FormikEffect from 'src/components/forms/effect';
import Field from 'src/components/forms/field';
import Accordion from 'src/components/pages/pageElements/accordion';
import FilterSummary from 'src/components/pages/pageElements/filterSummary';
import { ApolloClientContext } from 'src/data/ApolloClientContext';
import {
  getMultiSelectFilterField,
  getSingleSelectFilterField,
  StateReference,
  SubTypeReference,
  BooleanSelect,
} from 'src/util/filters';
import { getValuesFromQueryParams } from 'src/util/params/claims';

export default function ClaimsListFilters({ queryParams, handleFiltersChange, rootStore }) {
  const classes = useClaimsListFiltersStyles();
  // Filter values keep track of what we last applied in the search request
  const [filterValues, setFilterValues] = useState({});
  // Form values keep track of what we're currently selecting in the UI, so that
  // we may kick off another search request when we're ready
  const [localValues, setLocalValues] = useState({});

  // Show/hide condition for the convenient "filter" button that appears only
  // when one of the "x" remove buttons in the summary are used.
  const [showQuickFilter, setShowQuickFilter] = useState(false);

  const { apolloClient } = useContext(ApolloClientContext);

  useEffect(() => {
    (async () => {
      if (!apolloClient) {
        return;
      }

      const values = await getValuesFromQueryParams(queryParams, rootStore, apolloClient);

      setFilterValues(values);
      setLocalValues(values);
    })();
  }, [
    apolloClient,
    // Initial filter values are driven by the query param state
    queryParams,
    // rootStore needs to be mentioned so that possible changes to getProviderById are accounted for.
    // Deeper changes like receiving a chat message will not trigger the Effect.
    rootStore,
  ]);

  const validationSchema = Yup.object().shape({
    startDate: Yup.string().nullable(),
    endDate: Yup.string().nullable(),
    eventSubType: Yup.array().nullable(),
    patientId: Yup.array().nullable(),
    patientInformation_address_state: Yup.array().nullable(),
    payor_key: Yup.array().nullable(),
    changeControlNumber: Yup.string().nullable(),
    meta_status: Yup.array().nullable(),
    meta_type: Yup.array().nullable(),
    renderingProviderInformation_npi: Yup.array().nullable(),
  });

  const formatDateFilterValue = date => {
    const parsedDate = parseISO(date);
    return isValid(parsedDate) ? format(parsedDate, 'PP') : '';
  };

  const filterFields = [
    getSingleSelectFilterField('Date Range Start', 'startDate', DateControl, formatDateFilterValue),
    getSingleSelectFilterField('Date Range End', 'endDate', DateControl, formatDateFilterValue),
    getMultiSelectFilterField('SubType', 'eventSubType', SubTypeReference),
    getMultiSelectFilterField('Participants', 'patientId', PatientReferenceControl),
    getMultiSelectFilterField('State', 'patientInformation_address_state', StateReference),
    getMultiSelectFilterField('Payor', 'payor_key', PayorReferenceControl),
    getSingleSelectFilterField('Control Number', 'changeControlNumber', TextControl),
    getSingleSelectFilterField('Has ROI', 'hasReleaseOfInformation', BooleanSelect),
    getMultiSelectFilterField('Type', 'meta_type', TypeReferenceControl),
    getMultiSelectFilterField('Status', 'meta_status', StatusReferenceControl),
    getMultiSelectFilterField(
      'Provider',
      'renderingProviderInformation_npi',
      ProviderReferenceControl,
    ),
  ];

  // Object used to clear all values later by intentionally mapping every
  // filter field to null
  const clearedFiltersValue = mapValues(keyBy(filterFields, 'key'), () => null);

  const hasUnappliedChanges = filterFields.some(filter => {
    const appliedValue = filterValues[filter.key];
    const unappliedValue = localValues[filter.key];
    const match =
      // Either both filters match,
      isEqual(appliedValue, unappliedValue) ||
      // Or neither filter is currently selected
      (isNil(appliedValue) && isNil(unappliedValue));
    return !match;
  });

  function resetLocalValues() {
    setLocalValues(filterValues);
    setShowQuickFilter(false);
  }

  function applyUpdatedValues(updatedValues) {
    handleFiltersChange(updatedValues);
    setShowQuickFilter(false);
  }

  function clearAllLocalValues() {
    setLocalValues(clearedFiltersValue);
    if (hasUnappliedChanges) {
      setShowQuickFilter(hasUnappliedChanges);
    }
  }

  function removeLocalFilter(filterKey, multiFilterSubValue) {
    let newFilterValue = null;
    const generalFilterKey = filterKey.split('-')[0];
    if (Array.isArray(localValues[generalFilterKey])) {
      newFilterValue = localValues[generalFilterKey].filter(
        subValue => subValue !== multiFilterSubValue,
      );
    }
    setLocalValues({
      ...localValues,
      [generalFilterKey]: newFilterValue,
    });
  }

  return (
    <FormControl fullWidth className={classes.accordionContainer}>
      <div className={classes.formLabel}>
        <InputLabel shrink>Filtered By</InputLabel>
      </div>
      <Accordion
        additionalButtons={[
          {
            label: 'Clear All',
            onClick: clearAllLocalValues,
          },
        ]}
        submitLabel="Filter"
        summary={
          <FilterSummary
            filterFields={filterFields}
            localValues={localValues}
            hasUnappliedChanges={hasUnappliedChanges}
            removeLocalFilter={removeLocalFilter}
            onFilter={applyUpdatedValues}
            setShowQuickFilter={setShowQuickFilter}
            showQuickFilter={showQuickFilter}
          />
        }
        onCancel={resetLocalValues}
        onSubmit={() => applyUpdatedValues(localValues)}
      >
        <Formik
          validationSchema={validationSchema}
          initialValues={localValues}
          enableReinitialize
          onSubmit={noop} // Submission is handled in the Accordian onSubmit
        >
          {() => (
            // TODO: Do we need / should we have FormikEffect with an onChange handler here to set local values
            <div className={classes.formContainer}>
              <FormikEffect
                onChange={(current, prev) => {
                  // Update local values for fields that don't offer onChange but use `form.setFieldValue()` deeper down (i.e. ReferenceControl)
                  if (current.values !== prev.values) {
                    setLocalValues(current.values);
                  }
                }}
              />
              {filterFields.map(filterField => (
                <Field
                  key={filterField.key}
                  component={filterField.component}
                  value={localValues[filterField.key]}
                  {...filterField.props}
                />
              ))}
            </div>
          )}
        </Formik>
      </Accordion>
    </FormControl>
  );
}

const useClaimsListFiltersStyles = makeStyles({
  accordionContainer: {
    // Required to make input label appear
    display: 'flex',
    flexDirection: 'column',
  },
  formContainer: {
    '& > *': {
      display: 'inline-flex',
      marginBottom: 30,
      marginRight: 30,
      verticalAlign: 'inherit',
      width: 300,
    },
  },
  formLabel: {
    height: 20,
  },
  careTeamLabel: {
    fontStyle: 'italic',
  },
  wordWrapped: {
    minWidth: 100,
  },
});

function StatusReferenceControl(props) {
  const statuses = ['unknown', 'pending', 'held', 'needs-update', 'submitted', 'rejected'];
  return (
    <ReferenceControl
      {...props}
      loadOptions={async () =>
        statuses.map(s => ({
          value: s,
          label: startCase(s),
        }))
      }
    />
  );
}

function TypeReferenceControl(props) {
  const types = ['VISIT', 'MESSAGING'];
  return (
    <ReferenceControl
      {...props}
      loadOptions={async () =>
        types.map(t => ({
          value: t,
          label: startCase(t.toLowerCase()),
        }))
      }
    />
  );
}
