import keyBy from 'lodash/keyBy';

type StatesObject = {
  [key: string]: {
    name: string;
    abbreviation: string;
  };
};

/**
 * Function to get US state code
 *
 * @param {string} [state]
 * @returns {string} state abbreviation, or empty string if arg state doesn't match a US state name
 */
export function getStateAbbreviation(state: string | null | undefined): string {
  let stateAbbr = '';

  if (state && STATES[state]) {
    stateAbbr = STATES[state].abbreviation;
  }

  return stateAbbr;
}

export const STATES: StatesObject = {
  Alabama: {
    name: 'Alabama',
    abbreviation: 'AL',
  },
  Alaska: {
    name: 'Alaska',
    abbreviation: 'AK',
  },
  Arizona: {
    name: 'Arizona',
    abbreviation: 'AZ',
  },
  Arkansas: {
    name: 'Arkansas',
    abbreviation: 'AR',
  },
  California: {
    name: 'California',
    abbreviation: 'CA',
  },
  Colorado: {
    name: 'Colorado',
    abbreviation: 'CO',
  },
  Connecticut: {
    name: 'Connecticut',
    abbreviation: 'CT',
  },
  Delaware: {
    name: 'Delaware',
    abbreviation: 'DE',
  },
  Florida: {
    name: 'Florida',
    abbreviation: 'FL',
  },
  Georgia: {
    name: 'Georgia',
    abbreviation: 'GA',
  },
  Hawaii: {
    name: 'Hawaii',
    abbreviation: 'HI',
  },
  Idaho: {
    name: 'Idaho',
    abbreviation: 'ID',
  },
  Illinois: {
    name: 'Illinois',
    abbreviation: 'IL',
  },
  Indiana: {
    name: 'Indiana',
    abbreviation: 'IN',
  },
  Iowa: {
    name: 'Iowa',
    abbreviation: 'IA',
  },
  Kansas: {
    name: 'Kansas',
    abbreviation: 'KS',
  },
  Kentucky: {
    name: 'Kentucky',
    abbreviation: 'KY',
  },
  Louisiana: {
    name: 'Louisiana',
    abbreviation: 'LA',
  },
  Maine: {
    name: 'Maine',
    abbreviation: 'ME',
  },
  Maryland: {
    name: 'Maryland',
    abbreviation: 'MD',
  },
  Massachusetts: {
    name: 'Massachusetts',
    abbreviation: 'MA',
  },
  Michigan: {
    name: 'Michigan',
    abbreviation: 'MI',
  },
  Minnesota: {
    name: 'Minnesota',
    abbreviation: 'MN',
  },
  Mississippi: {
    name: 'Mississippi',
    abbreviation: 'MS',
  },
  Missouri: {
    name: 'Missouri',
    abbreviation: 'MO',
  },
  Montana: {
    name: 'Montana',
    abbreviation: 'MT',
  },
  Nebraska: {
    name: 'Nebraska',
    abbreviation: 'NE',
  },
  Nevada: {
    name: 'Nevada',
    abbreviation: 'NV',
  },
  'New Hampshire': {
    name: 'New Hampshire',
    abbreviation: 'NH',
  },
  'New Jersey': {
    name: 'New Jersey',
    abbreviation: 'NJ',
  },
  'New Mexico': {
    name: 'New Mexico',
    abbreviation: 'NM',
  },
  'New York': {
    name: 'New York',
    abbreviation: 'NY',
  },
  'North Carolina': {
    name: 'North Carolina',
    abbreviation: 'NC',
  },
  'North Dakota': {
    name: 'North Dakota',
    abbreviation: 'ND',
  },
  Ohio: {
    name: 'Ohio',
    abbreviation: 'OH',
  },
  Oklahoma: {
    name: 'Oklahoma',
    abbreviation: 'OK',
  },
  Oregon: {
    name: 'Oregon',
    abbreviation: 'OR',
  },
  Pennsylvania: {
    name: 'Pennsylvania',
    abbreviation: 'PA',
  },
  'Rhode Island': {
    name: 'Rhode Island',
    abbreviation: 'RI',
  },
  'South Carolina': {
    name: 'South Carolina',
    abbreviation: 'SC',
  },
  'South Dakota': {
    name: 'South Dakota',
    abbreviation: 'SD',
  },
  Tennessee: {
    name: 'Tennessee',
    abbreviation: 'TN',
  },
  Texas: {
    name: 'Texas',
    abbreviation: 'TX',
  },
  Utah: {
    name: 'Utah',
    abbreviation: 'UT',
  },
  Vermont: {
    name: 'Vermont',
    abbreviation: 'VT',
  },
  Virginia: {
    name: 'Virginia',
    abbreviation: 'VA',
  },
  Washington: {
    name: 'Washington',
    abbreviation: 'WA',
  },
  'West Virginia': {
    name: 'West Virginia',
    abbreviation: 'WV',
  },
  Wisconsin: {
    name: 'Wisconsin',
    abbreviation: 'WI',
  },
  Wyoming: {
    name: 'Wyoming',
    abbreviation: 'WY',
  },
};

/**
 * A map with state abbreviations as the keys and full names as the values.
 */
export const STATES_BY_ABBREVIATION = keyBy(
  Object.keys(STATES),
  state => STATES[state].abbreviation,
);
